import React, { useEffect, useState, useCallback } from 'react';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { notificationController } from 'controllers/notificationController';
import { useMounted } from '@app/hooks/useMounted';
import { useAuth } from '@app/contexts/AuthContext';
import { Space, Input, Button } from 'antd';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

const initialPagination: TablePaginationConfig = {
  current: 1,
  pageSize: 10,
};

interface CustomerDataRecord {
  transaction_id: string;
  lessee: string;
  property: string;
  category_name: string;
  phone: string;
  transaction_number: string;
  arrival_date: string;
  departure_date: string;
  nights: number;
  cancelled_reservation: boolean;
  amount: number;
  currency_symbol: string;
}

interface TableRow {
  key: string;
  property: string;
  channel: string;
  phone: string;
  reservationNumber: string;
  arrivalDate: string;
  departureDate: string;
  nights: number;
  reservationCancelled: boolean;
  totalRevenue: number;
  transactionId: string;
  currencySymbol: string;
  [key: string]: any;
}

interface CustomerInfoTableProps {
  customerId: string | undefined;
}

export const CustomerInfoTable: React.FC<CustomerInfoTableProps> = ({ customerId }) => {
  const [customerInfoRecords, setCustomerInfoRecords] = useState<CustomerDataRecord[]>([]);
  const { t } = useTranslation();
  const { isMounted } = useMounted();
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState<TablePaginationConfig>(initialPagination);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');

  const fetchRateRecords = useCallback(async () => {
    if (!customerId || !currentUser) return;

    setLoading(true);
    try {
      const token = await currentUser.getIdToken();
      const endpoint = `${process.env.REACT_APP_BACKEND_URL}/api/v1/lessee/info?lessee_id=${customerId}`;
      const response = await fetch(endpoint, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        if (isMounted.current) {
          setCustomerInfoRecords(data || []);
        }
      } else {
        notificationController.error({ message: t('error.fetchRateRecords') });
      }
    } catch (error) {
      notificationController.error({ message: t('error.fetchRateRecords') });
    } finally {
      setLoading(false);
    }
  }, [t, currentUser, isMounted, customerId]);

  useEffect(() => {
    fetchRateRecords();
  }, [fetchRateRecords]);

  const handleTableChange = (newPagination: TablePaginationConfig) => {
    setPagination(newPagination);
  };

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={t('common.searchName')}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
          >
            {t('common.search')}
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small">
            {t('common.reset')}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value: string | number | boolean, record: TableRow) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => (document.querySelector(`input`) as HTMLInputElement)?.select(), 100);
      }
    },
    render: (text: string) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys: string[], confirm: () => void, dataIndex: string) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const formatRevenue = (value: number) => {
    return value % 1 !== 0 ? value.toFixed(2) : value.toString();
  };

  const handleTransactionClick = (transaction_id: string) => {
    window.open(`/transaction/${transaction_id}`, '_blank');
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return '';
    }
    const day = date.getDate();
    const month = date.toLocaleDateString(undefined, { month: 'short' }).toLowerCase();
    const year = date.getFullYear();
    return `${day} ${t(`common.month3Letters.${month}`)} ${year}`;
  };

  const columns: ColumnsType<TableRow> = [
    {
      title: t('forms.transactionFormLabels.property'),
      dataIndex: 'property',
      key: 'property',
    },
    {
      title: t('forms.transactionFormLabels.channel'),
      dataIndex: 'channel',
    },
    {
      title: t('common.phone'),
      dataIndex: 'phone',
      key: 'phone',
      ...getColumnSearchProps('phone'),
    },
    {
      title: t('forms.transactionFormLabels.reservationNumber'),
      dataIndex: 'reservationNumber',
    },
    {
      title: t('forms.transactionFormLabels.arrivalDate'),
      dataIndex: 'arrivalDate',
      sorter: (a: TableRow, b: TableRow) => new Date(a.arrivalDate).getTime() - new Date(b.arrivalDate).getTime(),
      showSorterTooltip: false,
      render: (date: string) => formatDate(date),
      defaultSortOrder: 'descend', // Set default sort order to descending
    },
    {
      title: t('forms.transactionFormLabels.departureDate'),
      dataIndex: 'departureDate',
      sorter: (a: TableRow, b: TableRow) => new Date(a.departureDate).getTime() - new Date(b.departureDate).getTime(),
      showSorterTooltip: false,
      render: (date: string) => formatDate(date),
    },
    {
      title: t('forms.transactionFormLabels.nights'),
      dataIndex: 'nights',
      key: 'nights',
      sorter: (a, b) => a.nights - b.nights,
    },
    {
      title: t('forms.transactionFormLabels.reservationCancelled'),
      dataIndex: 'reservationCancelled',
      key: 'reservationCancelled',
      render: (cancelled: boolean) => (cancelled ? '✔️' : ''),
    },
    {
      title: `${t('common.totalRevenue')} (${customerInfoRecords[0]?.currency_symbol || ''})`,
      dataIndex: 'totalRevenue',
      key: 'totalRevenue',
      sorter: (a, b) => a.totalRevenue - b.totalRevenue,
      render: (text: number) => formatRevenue(text),
    },
    {
      title: t('tables.actions'),
      dataIndex: 'actions',
      render: (_: any, record: TableRow) => {
        return (
          <BaseSpace>
            <BaseButton type="ghost" onClick={() => handleTransactionClick(record.transactionId)}>
              {t('tables.open')}
            </BaseButton>
          </BaseSpace>
        );
      },
    },
  ];

  const tableRows = customerInfoRecords.map((customer) => {
    return {
      key: customer.transaction_number,
      property: customer.property,
      channel: customer.category_name,
      phone: customer.phone,
      reservationNumber: customer.transaction_number,
      arrivalDate: customer.arrival_date,
      departureDate: customer.departure_date,
      nights: customer.nights,
      reservationCancelled: customer.cancelled_reservation,
      totalRevenue: customer.amount,
      transactionId: customer.transaction_id,
      currencySymbol: customer.currency_symbol,
    };
  });

  return (
    <>
      <BaseTable
        columns={columns}
        dataSource={tableRows}
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
        scroll={{ x: 800 }}
        bordered
        sortDirections={['descend', 'ascend']}
      />
      <style>
        {`
          .total-row {
            background-color: #f0f0f0;
            font-weight: bold;
          }
          @media (max-width: 300px) {
            .property-select, .channel-select, .export-button {
              width: 100% !important;
              margin-bottom: 8px;
            }
            .export-button {
              font-size: 12px;
            }
          }
        `}
      </style>
    </>
  );
};
