import React from 'react';
import { useTranslation } from 'react-i18next';
import { BaseChart, getDefaultTooltipStyles } from '@app/components/common/charts/BaseChart';
import { dashboardPaddings } from '@app/components/medical-dashboard/DashboardCard/DashboardCard';
import { useResponsive } from '@app/hooks/useResponsive';
import { SeriesDataItem } from '@app/interfaces/interfaces';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { themeObject } from '@app/styles/themes/themeVariables';

interface RevParChartProps {
  dataIds: string[];
  dataRevPar: number[];
  dataRevParLastYear: number[];
  selectedDatePeriodBreakDown: string | undefined;
  dataCurrency: string;
  selectedDateIndex: string | number | null;
  selectedChartZoomRange: {
    startValue: number | null;
    endValue: number | null;
  };
  onInteraction: (index: string | number, bar_id: string, initialBarsToShow: number, dataLength: number) => void;
}

export const RevParChart: React.FC<RevParChartProps> = ({
  dataIds,
  dataRevPar,
  dataRevParLastYear,
  selectedDatePeriodBreakDown,
  dataCurrency,
  selectedDateIndex,
  selectedChartZoomRange,
  onInteraction,
}) => {
  const theme = useAppSelector((state) => state.theme.theme);

  const { t } = useTranslation();

  const { isTablet, isDesktop, isMobile } = useResponsive();

  const size = isDesktop ? 'xl' : isTablet ? 'md' : isMobile ? 'xs' : 'xs';

  // Determine the initial number of bars based on the device type
  const initialBarsToShow = isMobile ? 6 : 6;
  const startValue = dataIds.length > initialBarsToShow ? dataIds.length - initialBarsToShow : 0;

  // Function to handle click event on bars
  const handleClick = (params: { dataIndex: string | number; value: number }) => {
    const index = typeof params.dataIndex === 'string' ? parseInt(params.dataIndex, 10) : params.dataIndex;
    onInteraction(index, dataIds[index], initialBarsToShow, dataIds.length);
  };

  const currentYearColor = (params: { dataIndex: string | number; value: number }): string => {
    const index = typeof params.dataIndex === 'string' ? parseInt(params.dataIndex, 10) : params.dataIndex;
    const label = dataIds[index];
    return label === selectedDateIndex ? 'rgba(0, 53, 128, 1.0)' : 'rgba(0, 53, 128, 0.5)';
  };

  const lastYearColor = (): string => 'rgba(0, 53, 128, 0.2)';

  const lastYearEmphasisColor = 'rgba(0, 53, 128, 0.4)';

  const markLine = {
    symbol: 'none',
    data: [
      {
        type: 'average',
        name: 'Average',
      },
    ],
    label: {
      normal: {
        show: true,
        position: 'middle',
        formatter: (params: { value: number }) => {
          return `Avg: ${dataCurrency}${params.value.toFixed(1)}`;
        },
      },
    },
    silent: true,
  };

  const lastYearMarkLine = {
    ...markLine,
    label: {
      normal: {
        ...markLine.label.normal,
        formatter: (params: { value: number }) => {
          return `Avg (Last Year): ${dataCurrency}${params.value.toFixed(1)}`;
        },
      },
    },
  };

  const series = [
    {
      name: 'Current Year',
      barMaxWidth: 26,
      data: dataRevPar,
      type: 'bar',
      itemStyle: {
        borderRadius: 7,
        color: currentYearColor,
      },
      emphasis: {
        focus: 'series',
        blur: 'series',
        itemStyle: {
          color: 'rgba(0, 53, 128, 1.0)',
        },
      },
      markLine: markLine,
    },
  ];

  if (selectedDatePeriodBreakDown === 'month') {
    series.unshift({
      name: 'Last Year',
      barMaxWidth: 26,
      data: dataRevParLastYear,
      type: 'bar',
      itemStyle: {
        borderRadius: 7,
        color: lastYearColor,
      },
      emphasis: {
        focus: 'series',
        blur: 'series',
        itemStyle: {
          color: lastYearEmphasisColor,
        },
      },
      markLine: lastYearMarkLine,
    });
  }

  const option = {
    grid: {
      top: dashboardPaddings[size][0],
      right: dashboardPaddings[size][1],
      bottom: dashboardPaddings[size][1],
      left: dashboardPaddings[size][0],
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      axisTick: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      data: dataIds,
      position: 'bottom',
      axisLabel: {
        color: themeObject[theme].primary,
        fontWeight: 500,
        fontSize: 12,
      },
    },
    yAxis: {
      type: 'value',
      min: 0,
      axisLabel: {
        formatter: `${dataCurrency}{value}`,
        color: themeObject[theme].textLight,
        fontWeight: 500,
        fontSize: 14,
      },
    },
    series: series,
    dataZoom: [
      {
        type: 'inside',
        startValue: selectedChartZoomRange.startValue === null ? startValue : selectedChartZoomRange.startValue,
        endValue: selectedChartZoomRange.endValue === null ? dataIds.length - 1 : selectedChartZoomRange.endValue,
      },
    ],
    tooltip: {
      ...getDefaultTooltipStyles(themeObject[theme]),
      trigger: 'axis',
      formatter: (data: SeriesDataItem[]) => {
        if (!data[0]) return '';
        const date = data[0].axisValueLabel || ''; // Ensuring default if undefined
        const rev_par = data.length > 1 ? data[1].value.toFixed(2) : '0';
        const rev_parLastYear = data[0] ? data[0].value.toFixed(2) : '0';
        if (selectedDatePeriodBreakDown === 'month' && data.length > 1) {
          return `${date}<br/>${t('performance-dashboard.chart.rev-par')}: ${dataCurrency}${rev_par}<br/>${t(
            'performance-dashboard.chart.rev-par-last-year',
          )}: ${dataCurrency}${rev_parLastYear}`;
        } else {
          return `${date}<br/>${t('performance-dashboard.chart.rev-par')}: ${dataCurrency}${rev_parLastYear}`;
        }
      },
    },
    barGap: '-100%', // Bars overlap
    barCategoryGap: '50%', // Bars overlap
  };

  // Define custom event handlers for the chart
  const onEvents = {
    click: handleClick, // Call handleBarClick function when a bar is clicked
  };

  return <BaseChart option={option} height="100%" onEvents={onEvents} />;
};
