import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { BaseAvatar } from '@app/components/common/BaseAvatar/BaseAvatar';
import { DeleteOutlined, UserOutlined } from '@ant-design/icons';
import { Space, Typography, Input, Button, Form, Spin } from 'antd';
import { notificationController } from '@app/controllers/notificationController';
import { useAuth } from '@app/contexts/AuthContext';
import Error404Page from '@app/pages/Error404Page';
import styled from 'styled-components';
import { BaseCard as CommonCard } from '@app/components/common/BaseCard/BaseCard';

export const TablesWrapper = styled.div`
  margin-top: 1.875rem;
`;

export const Card = styled(CommonCard)`
  margin-bottom: 2rem;
`;

export const DeleteButton = styled(Button)`
  background-color: red;
  border-color: red;
  color: white;

  &:hover,
  &:focus {
    background-color: #ff8080;
    border-color: #ff8080;
    color: white;
  }
`;

export interface CustomerDetailsType {
  id?: string;
  user_id?: string;
  name: string;
  phone: string | undefined;
  email: string | undefined;
  created_at?: string;
  updated_at?: string | undefined;
  notes: string | undefined;
}

const CustomerFormPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { customerId } = useParams<{ customerId: string }>();
  const { currentUser } = useAuth();
  const [customerDetails, setCustomerDetails] = useState<CustomerDetailsType | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isDeleting, setDeleting] = useState(false);
  const [hasError, setHasError] = useState(false); // State to handle error
  const [form] = Form.useForm();

  const fetchCustomerDetails = useCallback(async () => {
    if (!currentUser || !customerId) return;

    setLoading(true);
    try {
      const token = await currentUser.getIdToken();
      const endpoint = `${process.env.REACT_APP_BACKEND_URL}/api/v1/lessee?lessee_id=${customerId}`;
      const response = await fetch(endpoint, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setCustomerDetails(data[0] || {});
        form.setFieldsValue(data[0] || {}); // Set form fields with the fetched data
      } else if (response.status === 404) {
        setHasError(true); // Set error state to true if status is 404
        return;
      } else if (response.status === 400) {
        const errorData = await response.json();
        notificationController.error({ message: errorData.detail });
      } else {
        notificationController.error({ message: t('error.fetchCustomerDetails') });
      }
    } catch (error) {
      notificationController.error({ message: t('error.fetchCustomerDetails') });
    } finally {
      setLoading(false);
    }
  }, [customerId, t, currentUser, form]);

  useEffect(() => {
    if (customerId) {
      fetchCustomerDetails();
    } else {
      form.resetFields(); // Clear form fields if no customerId is provided
    }
  }, [fetchCustomerDetails, customerId, form]);

  const onFinish = async (values: CustomerDetailsType) => {
    setLoading(true);
    try {
      const token = await currentUser?.getIdToken();
      const endpoint = customerId
        ? `${process.env.REACT_APP_BACKEND_URL}/api/v1/lessee/${customerId}`
        : `${process.env.REACT_APP_BACKEND_URL}/api/v1/lessee`;
      const method = customerId ? 'PATCH' : 'POST';

      const response = await fetch(endpoint, {
        method,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(values),
      });

      if (response.ok) {
        const successMessage = customerId ? t('common.success') : t('common.createSuccess');
        notificationController.success({ message: successMessage });
        const data = await response.json();
        navigate(`/customer/${data.id}`); // Redirect to the new customer's page
        setCustomerDetails(values);
      } else if (response.status === 400) {
        const errorData = await response.json();
        notificationController.error({
          message: t(`${errorData.detail.code}`, { name: errorData.detail.lessee_name }),
        });
      } else {
        notificationController.error({ message: t('common.errorOccurred') });
      }
    } catch (error) {
      notificationController.error({ message: t('common.errorOccurred') });
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    setDeleting(true); // Set deleting state to true
    try {
      if (customerId && currentUser) {
        const token = await currentUser.getIdToken();
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/lessee/${customerId}`, {
          method: 'DELETE',
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 204) {
          notificationController.success({ message: t('common.deleteSuccess') });
          navigate('/customers'); // Navigate away or refresh the list
        } else {
          // Handle non-204 responses and use the message from the backend
          const responseData = await response.json();
          const errorMessage = responseData.detail || t('common.errorOccurred');
          notificationController.error({ message: errorMessage });
        }
      }
    } catch (error) {
      // This catch block will handle network errors and cases where the response could not be parsed
      notificationController.error({ message: t('common.errorOccurred') });
      console.error('Failed to delete the category:', error);
    } finally {
      setDeleting(false);
    }
  };

  if (hasError) {
    return <Error404Page />;
  }

  if (loading && !customerDetails) {
    return <Spin tip={t('loading')} />;
  }

  return (
    <TablesWrapper>
      <Card
        id="customer-table"
        title={
          <Space>
            <BaseAvatar size={40} icon={<UserOutlined />} />
          </Space>
        }
        padding="1.25rem 1.25rem 0"
      >
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item
            label={t('common.name')}
            name="name"
            rules={[{ required: true, message: t('common.nameRequired') }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label={t('common.phone')} name="phone">
            <Input />
          </Form.Item>
          <Form.Item label={t('common.email')} name="email">
            <Input />
          </Form.Item>
          <Form.Item label={t('forms.transactionFormLabels.notes')} name="notes">
            <Input.TextArea rows={4} />
          </Form.Item>
          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit" loading={loading}>
                {t('common.submit')}
              </Button>
              {customerId && (
                <DeleteButton onClick={handleDelete} icon={<DeleteOutlined />} loading={isDeleting}>
                  {t('common.delete')}
                </DeleteButton>
              )}
            </Space>
          </Form.Item>
        </Form>
      </Card>
    </TablesWrapper>
  );
};

export default CustomerFormPage;
