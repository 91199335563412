export interface ConfigDateSelect {
  id: string;
  name: string;
  displayName: string;
}

export const dateSelects: ConfigDateSelect[] = [
  {
    id: 'month',
    name: 'By Months',
    displayName: 'performance-dashboard.selects.byMonths',
  },
  {
    id: 'year',
    name: 'By Years',
    displayName: 'performance-dashboard.selects.byYears',
  },
  {
    id: 'all_history',
    name: 'All History',
    displayName: 'performance-dashboard.selects.allHistory',
  },
];
