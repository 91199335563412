// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  updateProfile,
} from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get a reference to the Auth service
export const auth = getAuth(app);

export const handleGoogleLogin = async (navigate, setMessage, setMessageType, lang) => {
  try {
    const provider = new GoogleAuthProvider();
    const result = await signInWithPopup(auth, provider);
    const user = result.user;

    const token = await user.getIdToken(); // Get the JWT token
    console.log(token);

    // POST request to backend with the JWT token
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/login`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'Accept-Language': lang,
      },
    });

    if (response.status !== 200) {
      auth.signOut();
      const responseData = await response.json();
      const errorMessage = responseData.detail || t('common.errorOccurred');
      setMessage(errorMessage);
      setMessageType('error');
    } else {
      navigate('/');
    }
  } catch (error) {
    // Handling the specific error when the popup is closed by the user
    if (error.code === 'auth/popup-closed-by-user') {
      navigate('/auth/login'); // Navigate to email verification page
      // Optionally, set a message to inform the user that the popup was closed, or simply ignore this error.
      // setMessage('Popup closed by user');
      // setMessageType('info');
    } else {
      // Handle other errors here
      navigate('/auth/login'); // Navigate to email verification page
      // Optionally, inform the user of the error
      // setMessage('Failed to login with Google. Please try again.');
      // setMessageType('error');
    }
  }
};

export const handleLogin = async (values, navigate, setMessage, setMessageType) => {
  const email = values.email; // Access the email input's value
  const password = values.password; // Access the password input's value
  const auth = getAuth(); // Initialize Firebase Auth

  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;
    const token = await user.getIdToken(); // Get the JWT token

    console.log(token);

    if (!user.emailVerified) {
      setMessage('You need to verify your email address');
      setMessageType('error');
      return;
    }

    // Retrieve language preference from local storage
    const lang = localStorage.getItem('userLang') || 'en';

    // POST request to backend with the JWT token
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/login`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'Accept-Language': lang,
      },
    });

    if (response.status !== 200) {
      auth.signOut();
      const responseData = await response.json();
      const errorMessage = responseData.detail || t('common.errorOccurred');
      setMessage(errorMessage);
      setMessageType('error');
    } else {
      navigate('/');
    }
    // Here, redirect the user or update the UI accordingly
  } catch (error) {
    setMessageType('error');
    let friendlyMessage = '';
    switch (error.code) {
      case 'auth/invalid-credential':
        friendlyMessage = 'Incorrect password. Please try again.';
        break;
      case 'auth/user-disabled':
        friendlyMessage = 'Your account has been disabled. Please contact support for more information.';
        break;
      case 'auth/too-many-requests':
        friendlyMessage = 'Too many failed login attempts. Please try again later or reset your password.';
        break;
      case 'auth/network-request-failed':
        friendlyMessage = 'Network error. Please check your internet connection and try again.';
        break;
      default:
        friendlyMessage = 'An error occurred during login. Please try again.';
    }
    setMessage(friendlyMessage);
  }
};

export const handleSignUp = async (values, navigate, setUser) => {
  const { email, password, firstName, lastName, lang } = values;
  const auth = getAuth();

  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    await updateProfile(user, {
      displayName: `${firstName} ${lastName}`,
    });

    // Store language preference in local storage
    localStorage.setItem('userLang', lang || 'en');

    // Send email verification
    await sendEmailVerification(user);

    // Update state with the new user
    setUser(user);

    // Navigate to email verification page
    navigate('/auth/email-verification');
  } catch (error) {
    if (error.code === 'auth/email-already-in-use') {
      alert('Email already in use. Please try another email.');
    }
  }
};

export const resendVerificationEmail = async (navigate, user, setMessage, setMessageType) => {
  const auth = getAuth();
  if (user) {
    await sendEmailVerification(auth.currentUser)
      .then(() => {
        // Email verification sent
        setMessage('Verification email resent. Please check your inbox.');
        setMessageType('success');
        navigate('/auth/email-verification'); // Navigate to email verification page
      })
      .catch((error) => {
        // Handle errors here
        setMessage('Error resending verification email. Please try again later.');
        setMessageType('error');
      });
  } else {
    // No user signed in
    setMessage('No user signed in.');
    setMessageType('error');
  }
};

export const handleForgotPassword = async (values, setMessage, setMessageType) => {
  const email = values.email; // Access the email input's value

  sendPasswordResetEmail(auth, email)
    .then(() => {
      // Password reset email sent.
      setMessage('Check your inbox for further instructions.');
      setMessageType('success');
    })
    .catch((error) => {
      // Handle errors here.
      if (error.code === 'auth/user-not-found') {
        // Inform the user that no user was found with the provided email address
        setMessage('No user found with that email address.');
      } else {
        // For any other errors, you can display a generic error message
        setMessage('Failed to send password reset email. Please try again.');
      }
      setMessageType('error');
    });
};

export const handleLogout = async () => {
  const auth = getAuth();
  auth.signOut();
};
