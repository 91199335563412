import { TransactionTimelineData } from '@app/interfaces/interfaces';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as S from '@app/pages/DashboardPages/DashboardPage.styles';
import { BaseCollapse } from '@app/components/common/BaseCollapse/BaseCollapse';
import { formatDate } from '@app/utils/DateUtils';
import * as S1 from '@app/pages/TransactionsTimelinePage.styles';
import { useNavigate } from 'react-router-dom';
import { formatCurrency } from '@app/utils/utils';
import { useResponsive } from '@app/hooks/useResponsive';
import styled from 'styled-components';

export const StyledCard = styled(S.Card)`
  margin-bottom: 1; // Remove any margin-bottom
  .ant-card-body {
    padding-top: 1; // Remove padding-top from the card body
  }
`;

export const TimelineCard: React.FC<{ data: TransactionTimelineData | null }> = ({ data }) => {
  const { t, i18n } = useTranslation();
  const { isTablet } = useResponsive();
  const navigate = useNavigate();

  const transactionsByDate = data?.transactions_data?.transactions;
  const currency = data?.currency.symbol || '';

  if (!transactionsByDate || transactionsByDate.length === 0) {
    return (
      <S.Card title={t('transactions-timeline.transactions.title')}>
        <S.CollapseWrapper activeKey={['1']}>
          <BaseCollapse.Panel showArrow={false} header={''} key={1}>
            <S1.TransactionItem>{t('transactions-timeline.transactions.noTransactions')}</S1.TransactionItem>
          </BaseCollapse.Panel>
        </S.CollapseWrapper>
      </S.Card>
    );
  }

  const allPanelKeys = transactionsByDate.map((_, index) => `${index}`);

  const handleTransactionClick = (transaction_id: string) => {
    navigate(`/transaction/${transaction_id}`);
  };

  return (
    <StyledCard title={t('transactions-timeline.transactions.title')}>
      <S.CollapseWrapper activeKey={allPanelKeys}>
        {transactionsByDate.map((transactionGroup, index) => (
          <BaseCollapse.Panel
            showArrow={false}
            header={
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  cursor: 'default',
                  fontSize: isTablet ? '16px' : '15px', // Responsive font size based on device type
                }}
              >
                <div>{formatDate(transactionGroup.date, i18n.language)}</div>
                <div>{formatCurrency(transactionGroup.sum, currency)}</div>
              </div>
            }
            key={index}
          >
            {transactionGroup.transactions.map((transaction) => (
              <S1.TransactionItem
                key={transaction.transaction_id}
                onClick={() => handleTransactionClick(transaction.transaction_id)}
                style={{
                  cursor: 'pointer',
                  fontSize: isTablet ? '16px' : '14px', // Smaller font size if not a tablet
                }}
              >
                <div style={{ flexGrow: 1 }}>
                  <div style={{ fontWeight: 'bold', fontSize: isTablet ? '16px' : '14px' }}>
                    {transaction.category_name}
                  </div>
                  {transaction.label_names && transaction.label_names.length > 0 && (
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                      {transaction.label_names.map((label_name, labelIndex) => (
                        <button
                          key={`${transaction.transaction_id}-${label_name}-${labelIndex}`}
                          style={{
                            background: '#003580',
                            border: 'none',
                            borderRadius: '5px',
                            padding: isTablet ? '5px 7px' : '3px 5px',
                            whiteSpace: 'nowrap',
                            color: 'white',
                            cursor: 'pointer',
                            fontSize: isTablet ? '16px' : '13px',
                            marginTop: isTablet ? '5px' : '3px',
                            marginRight: isTablet ? '5px' : '3px',
                          }}
                        >
                          {label_name}
                        </button>
                      ))}
                    </div>
                  )}
                  <span
                    style={{ display: 'block', marginTop: '5px', color: 'grey', fontSize: isTablet ? '16px' : '13px' }}
                  >
                    {transaction.property_name}
                  </span>
                </div>
                <span
                  style={{
                    whiteSpace: 'nowrap', // Add this line
                    color: transaction.category_type === 'Income' ? 'green' : 'red',
                    fontWeight: 'bold',
                    marginLeft: 'auto',
                    fontSize: isTablet ? '16px' : '13px', // Smaller font size if not a tablet
                  }}
                >
                  {formatCurrency(transaction.amount, currency)}
                </span>
              </S1.TransactionItem>
            ))}
          </BaseCollapse.Panel>
        ))}
      </S.CollapseWrapper>
    </StyledCard>
  );
};
