import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import tw from 'twin.macro';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { ReactComponent as GoogleIcon } from '@app/assets/icons/google.svg';
import logo from '@app/assets/logo.svg';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import * as S from './SignUpForm.styles';
import { handleGoogleLogin, handleSignUp } from '@app/utils/Firebase.js';
import { useUser } from '@app/utils/UserSignUpContext';
import styled from 'styled-components';

const LogoImage = tw.img`h-12 mx-auto`;
const LogoLink = tw.a``;

type MessageProps = {
  type: 'error' | 'success';
};

const Message = styled.p<MessageProps>`
  ${tw`mt-6 text-center py-3 rounded-lg`}
  ${(props) => (props.type === 'error' ? tw`bg-red-100 text-red-700` : tw`bg-green-100 text-green-700`)}
`;

interface UserContextType {
  user: unknown; // Consider using a more specific type instead of any
  setUser: (user: unknown) => void; // Adjust the user type as needed
}

export const SignUpForm: React.FC = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState<string | null>(null); // Added state for message
  const [messageType, setMessageType] = useState<'error' | 'success' | ''>(''); // Added state for message type
  const validatedMessageType: 'error' | 'success' = messageType === '' ? 'success' : messageType;
  const { user, setUser } = useUser() as UserContextType;

  const logoLinkUrl = '/';

  const termsOfServiceUrl =
    i18n.language === 'el'
      ? 'https://www.myrentalytics.com/el/terms-of-service'
      : 'https://www.myrentalytics.com/en/terms-of-service';
  const privacyPolicyUrl =
    i18n.language === 'el'
      ? 'https://www.myrentalytics.com/el/privacy-policy'
      : 'https://www.myrentalytics.com/en/privacy-policy';

  return (
    <Auth.FormWrapper>
      <BaseForm
        layout="vertical"
        onFinish={(values) => handleSignUp({ ...values, lang: i18n.language }, navigate, setUser)}
        requiredMark="optional"
      >
        <LogoLink href={logoLinkUrl}>
          <LogoImage src={logo} />
        </LogoLink>
        <S.Title>{t('common.signUp')}</S.Title>
        <Auth.FormItem
          name="firstName"
          label={t('common.firstName')}
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInput placeholder={t('common.firstName')} />
        </Auth.FormItem>
        <Auth.FormItem
          name="lastName"
          label={t('common.lastName')}
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInput placeholder={t('common.lastName')} />
        </Auth.FormItem>
        <Auth.FormItem
          name="email"
          label={t('common.email')}
          rules={[
            { required: true, message: t('common.requiredField') },
            {
              type: 'email',
              message: t('common.notValidEmail'),
            },
          ]}
        >
          <Auth.FormInput placeholder={t('common.email')} />
        </Auth.FormItem>
        <Auth.FormItem
          label={t('common.password')}
          name="password"
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInputPassword placeholder={t('common.password')} />
        </Auth.FormItem>
        <Auth.FormItem
          label={t('common.confirmPassword')}
          name="confirmPassword"
          dependencies={['password']}
          rules={[
            { required: true, message: t('common.requiredField') },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t('common.confirmPasswordError')));
              },
            }),
          ]}
        >
          <Auth.FormInputPassword placeholder={t('common.confirmPassword')} />
        </Auth.FormItem>
        <Auth.ActionsWrapper>
          <BaseForm.Item
            name="termOfUse"
            valuePropName="checked"
            rules={[{ required: true, message: t('common.AgreementRequired') }]}
            noStyle
          >
            <Auth.FormCheckbox>
              <Auth.Text>
                {t('signup.agree')}{' '}
                <Link to={termsOfServiceUrl} target={'_blank'}>
                  <Auth.LinkText>{t('signup.termOfUse')}</Auth.LinkText>
                </Link>{' '}
                {t('signup.and')}{' '}
                <Link to={privacyPolicyUrl} target={'_blank'}>
                  <Auth.LinkText>{t('signup.privacyOPolicy')}</Auth.LinkText>
                </Link>
              </Auth.Text>
            </Auth.FormCheckbox>
          </BaseForm.Item>
        </Auth.ActionsWrapper>
        <BaseForm.Item noStyle>
          <Auth.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            {t('common.signUp')}
          </Auth.SubmitButton>
        </BaseForm.Item>
        <BaseForm.Item noStyle>
          <Auth.SocialButton
            type="default"
            htmlType="button"
            onClick={() => handleGoogleLogin(navigate, setMessage, setMessageType, i18n.language)}
          >
            <Auth.SocialIconWrapper>
              <GoogleIcon />
            </Auth.SocialIconWrapper>
            {t('signup.googleLink')}
          </Auth.SocialButton>
        </BaseForm.Item>
        <Auth.FooterWrapper>
          <Auth.Text>
            {t('signup.alreadyHaveAccount')}{' '}
            <Link to="/auth/login">
              <Auth.LinkText>{t('common.here')}</Auth.LinkText>
            </Link>
          </Auth.Text>
        </Auth.FooterWrapper>
      </BaseForm>
      {message && <Message type={validatedMessageType}>{message}</Message>}
    </Auth.FormWrapper>
  );
};
