import React, { useState } from 'react';
import { ProfileOverlay } from '../ProfileOverlay/ProfileOverlay';
import { useResponsive } from '@app/hooks/useResponsive';
import * as S from './ProfileDropdown.styles';
import { BasePopover } from '@app/components/common/BasePopover/BasePopover';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { useAuth } from '@app/contexts/AuthContext';
import defaultUserIcon from '@app/assets/images/user-default-icon.svg';

export const ProfileDropdown: React.FC = () => {
  const { isDesktop, isTablet } = useResponsive(); // Get both desktop and tablet information
  const { currentUser, userDetails } = useAuth();
  const [isPopoverVisible, setPopoverVisible] = useState(true); // Default visibility set to false

  const handlePopoverVisibleChange = (visible: boolean) => {
    setPopoverVisible(visible);
  };

  const trimToTwentyChars = (str: string | null) => {
    const safeStr = str || ''; // Fallback to empty string if str is null/undefined
    return safeStr.length > 20 ? `${safeStr.substring(0, 20)}...` : safeStr;
  };

  const [isHovered, setIsHovered] = useState(false);

  const fullName = userDetails ? `${userDetails.first_name || ''} ${userDetails.last_name || ''}`.trim() : '';
  const displayName = trimToTwentyChars(fullName || currentUser?.email || '');

  // Render the BasePopover only for desktop and tablet devices
  const popoverContent = (
    <BasePopover
      content={<ProfileOverlay />}
      trigger="click"
      visible={isPopoverVisible}
      onVisibleChange={handlePopoverVisibleChange}
    >
      <S.ProfileDropdownHeader as={BaseRow} gutter={[10, 10]} align="middle">
        <div onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
          <S.StyledAvatar
            src={currentUser?.photoURL || defaultUserIcon} // Check if currentUser is not null
            alt="User"
            shape="circle"
            size={40}
            className={isHovered ? 'hovered' : ''}
          />
          {isDesktop && <S.DisplayName className={isHovered ? 'hovered' : ''}>{displayName}</S.DisplayName>}
        </div>
      </S.ProfileDropdownHeader>
    </BasePopover>
  );

  return currentUser ? (isDesktop || isTablet ? popoverContent : null) : null;
};
