import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { transactionHelpCenterItems } from './HelpCenterTransactionsPage';

const MenuList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const MenuItem = styled.li`
  margin-bottom: 10px;
  border-radius: 5px;
  transition: background-color 0.3s;

  &.active {
    background-color: #d0e0f0;
    font-weight: bold;
  }

  &:hover {
    background-color: #f0f0f0;
  }
`;

const StyledLink = styled(Link)`
  display: block;
  padding: 10px 15px;
  text-decoration: none;
  color: #333;
  border-radius: 5px;

  &:hover {
    color: #0073e6;
  }
`;

const HelpMenuTransactions: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <MenuList>
      {transactionHelpCenterItems.map((link, index) => (
        <MenuItem key={index} className={location.pathname === link.to ? 'active' : ''}>
          <StyledLink to={link.to}>{t(link.textKey)}</StyledLink>
        </MenuItem>
      ))}
    </MenuList>
  );
};

export default HelpMenuTransactions;
