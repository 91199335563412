import React from 'react';
import { TaxAndCommissionTable } from '../TaxAndCommissionTable/TaxAndCommissionTable';
import { useTranslation } from 'react-i18next';
import * as S from './Tables.styles';

export const TaxAndCommissionTables: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <S.TablesWrapper>
        <S.Card id="tax-and-commission-table" title={t('tables.taxAndCommissionTable')} padding="1.25rem 1.25rem 0">
          <TaxAndCommissionTable />
        </S.Card>
      </S.TablesWrapper>
    </>
  );
};
