import React, { useEffect, useState } from 'react';
import { useAuth } from '@app/contexts/AuthContext';
import { Property, getProperties } from '@app/api/properties.api';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import { useTranslation } from 'react-i18next';

interface PropertiesSelectProps {
  itemStyle?: React.CSSProperties;
  onPropertySelect: (propertyId: string | undefined) => void; // Callback function to pass the selected property ID to the parent
}

export const PropertiesSelect: React.FC<PropertiesSelectProps> = ({ itemStyle, onPropertySelect }) => {
  const { t } = useTranslation();
  const { currentUser } = useAuth();
  const [properties, setProperties] = useState<Property[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedPropertyId, setSelectedPropertyId] = useState<string | undefined>();
  const [hoveredPropertyId, setHoveredPropertyId] = useState<string | undefined>();

  useEffect(() => {
    if (currentUser) {
      getProperties(currentUser).then((res) => setProperties(res));
    } else {
      setProperties([]);
    }
  }, [currentUser]);

  const handlePropertySelect = (id: string | undefined) => {
    setSelectedPropertyId(id); // Update the selected property state
    onPropertySelect(id); // Call the passed callback function with the selected property ID
    setIsModalOpen(false);
  };

  return (
    <>
      <BaseButton style={{ marginRight: '8px' }} onClick={() => setIsModalOpen(true)}>
        {t('performance-dashboard.selects.select-property')}
      </BaseButton>
      <BaseModal
        title={t('performance-dashboard.selects.select-property')}
        centered
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
        size="small"
      >
        <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
          <div
            onClick={() => handlePropertySelect(undefined)}
            onMouseEnter={() => setHoveredPropertyId('all')}
            onMouseLeave={() => setHoveredPropertyId(undefined)}
            style={{
              ...itemStyle,
              backgroundColor:
                selectedPropertyId === undefined ? '#f0f0f0' : hoveredPropertyId === 'all' ? '#e0e0e0' : '',
              transition: 'background-color 0.3s ease',
            }}
          >
            {t('performance-dashboard.selects.all-properties')}
          </div>
          {properties.map((property) => (
            <div
              key={property.id}
              onMouseEnter={() => setHoveredPropertyId(property.id)}
              onMouseLeave={() => setHoveredPropertyId(undefined)}
              onClick={() => handlePropertySelect(property.id)}
              style={{
                ...itemStyle,
                backgroundColor:
                  selectedPropertyId === property.id ? '#f0f0f0' : hoveredPropertyId === property.id ? '#e0e0e0' : '',
                transition: 'background-color 0.3s ease',
              }}
            >
              {property.name}
            </div>
          ))}
        </div>
      </BaseModal>
    </>
  );
};
