import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseCard } from '../../common/BaseCard/BaseCard';
import { PerformanceSTRMetricsData } from 'interfaces/interfaces';
import styled from 'styled-components';
import { AverageLengthOfStayChart } from './AverageLengthOfStayChart';

interface AverageLengthOfStayCardProps {
  data: PerformanceSTRMetricsData | null;
  selectedDatePeriodBreakDown: string | undefined;
  selectedDateIndex: string | number | null;
  selectedChartZoomRange: {
    startValue: number | null;
    endValue: number | null;
  };
  onInteraction: (index: string | number, bar_id: string, initialBarsToShow: number, dataLength: number) => void;
}

export const AverageLengthOfStayCard: React.FC<AverageLengthOfStayCardProps> = ({
  data,
  selectedDatePeriodBreakDown,
  selectedDateIndex,
  selectedChartZoomRange,
  onInteraction,
}) => {
  const { t } = useTranslation();

  const [dataIds, setDataIds] = useState<string[]>([]);
  const [dataAverageLengthOfStay, setDataAverageLengthOfStay] = useState<number[]>([]);
  const [dataAverageLengthOfStayLastYear, setDataAverageLengthOfStayLastYear] = useState<number[]>([]);

  // When 'data' changes, update state for IDs, incomes, and expenses
  useEffect(() => {
    if (data && data.avg_length_of_stay_data) {
      const ids: string[] = data.avg_length_of_stay_data.map((item) => item.id);
      const average_length_of_stay: number[] = data.avg_length_of_stay_data.map((item) => item.average_length_of_stay);

      setDataIds(ids);
      setDataAverageLengthOfStay(average_length_of_stay);

      if (selectedDatePeriodBreakDown === 'month') {
        // Derive last year's data
        const lastYearRates: number[] = data.avg_length_of_stay_data.map((item) => {
          const lastYearDate = new Date(item.start_date);
          lastYearDate.setFullYear(lastYearDate.getFullYear() - 1);

          // Preserve the original timezone offset
          const timezoneOffset = new Date(item.start_date).getTimezoneOffset();
          const lastYearDateWithTimezone = new Date(lastYearDate.getTime() - timezoneOffset * 60000);
          const lastYearId = lastYearDateWithTimezone.toISOString().slice(0, 7);

          const lastYearItem = data.avg_length_of_stay_data.find((i) => i.id === lastYearId);
          return lastYearItem ? lastYearItem.average_length_of_stay : 0;
        });

        setDataAverageLengthOfStayLastYear(lastYearRates);
      } else {
        setDataAverageLengthOfStayLastYear([]); // Clear last year's data if not monthly breakdown
      }
    }
  }, [data]); // Dependency array ensures this runs when `data` changes

  return (
    <AverageLengthOfStayCardStyled
      id="activity"
      title={t('performance-dashboard.avg-length-of-stay.title')}
      padding={0}
    >
      <AverageLengthOfStayChart
        dataIds={dataIds || []}
        dataAverageLengthOfStay={dataAverageLengthOfStay || []}
        dataAverageLengthOfStayLastYear={dataAverageLengthOfStayLastYear || []}
        selectedDatePeriodBreakDown={selectedDatePeriodBreakDown}
        selectedDateIndex={selectedDateIndex}
        selectedChartZoomRange={selectedChartZoomRange}
        onInteraction={onInteraction}
      />
    </AverageLengthOfStayCardStyled>
  );
};

const AverageLengthOfStayCardStyled = styled(BaseCard)`
  width: 100%;
  height: 100%;
`;
