export const getThisMonthStartDate = (): string => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1; // Months are zero-based, so we add 1
  return `${year}-${month.toString().padStart(2, '0')}-01`;
};

export const getThisMonthEndDate = (): string => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1; // Months are zero-based, so we add 1
  const lastDayOfMonth = new Date(year, month, 0);
  return `${year}-${month.toString().padStart(2, '0')}-${lastDayOfMonth.getDate().toString().padStart(2, '0')}`;
};

export function formatDate(dateString: string, locale: string): string {
  const date = new Date(dateString);
  return date.toLocaleDateString(locale, { month: 'long', day: 'numeric' });
}

export function convertDateToISO(dateString: string) {
  // Create a Date object from the input string
  const date = new Date(dateString);

  // Convert the date to ISO string and remove milliseconds and timezone information
  const isoString = date.toISOString();

  // Since you need the time to be set to "00:00:00" and to represent UTC time 'Z',
  // the beginning of the ISO string is kept (up to the date), and the time is hardcoded as required.
  const formattedDate = isoString.substring(0, 10) + 'T00:00:00Z';

  return formattedDate;
}

export const formatDateRange = (startDateStr: string, endDateStr: string, monthNames: string[]): string => {
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);

  const startDay = startDate.getDate();
  const startMonth = monthNames[startDate.getMonth()];
  const startYear = startDate.getFullYear();

  const endDay = endDate.getDate();
  const endMonth = monthNames[endDate.getMonth()];
  const endYear = endDate.getFullYear();

  if (startYear === endYear) {
    if (startMonth === endMonth) {
      return `${startDay} ${startMonth} ${startYear} - ${endDay} ${endMonth} ${endYear}`;
    }
    return `${startDay} ${startMonth} ${startYear} - ${endDay} ${endMonth} ${endYear}`;
  } else {
    return `${startDay} ${startMonth} ${startYear} - ${endDay} ${endMonth} ${endYear}`;
  }
};
