import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { notificationController } from 'controllers/notificationController';
import { useMounted } from '@app/hooks/useMounted';
import { useAuth } from '@app/contexts/AuthContext';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { DatePicker, Space, Input, Button, Select, Alert } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import moment, { Moment } from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';
import * as XLSX from 'xlsx';
import Highlighter from 'react-highlight-words';

const { RangePicker } = DatePicker;
const { Option } = Select;

const initialPagination: TablePaginationConfig = {
  current: 1,
  pageSize: 10,
};

interface RateDataRecord {
  id: string;
  transaction_id: string;
  user_id: string;
  created_at: string;
  rate_name: string;
  rate_value: number;
  transaction_property_name: string;
  transaction_category_name: string;
  transaction_arrival_date: string;
  transaction_departure_date: string;
  transaction_nights: number;
  transaction_host_payment_amount: number;
  transaction_total_amount: number;
  transaction_guest_id: string;
  transaction_guest_name: string;
  currency_symbol: string;
}

interface RateDataUnprocessed {
  transaction_id: string;
}

interface TableRow {
  key: string;
  name: string;
  property: string;
  channel: string;
  arrivalDate: string;
  departureDate: string;
  nights: number;
  hostPaymentAmount: number;
  payoutAmount: number;
  taxableAmount?: number;
  transactionId: string;
  currencySymbol: string;
  [key: string]: any;
}

export const TaxAndCommissionTable: React.FC = () => {
  const [rateDataRecords, setRateDataRecords] = useState<RateDataRecord[]>([]);
  const [unprocessedTransactions, setUnprocessedTransactions] = useState<RateDataUnprocessed[]>([]);
  const { t } = useTranslation();
  const { isMounted } = useMounted();
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState<TablePaginationConfig>(initialPagination);
  const [filteredRateDataRecords, setFilteredRateDataRecords] = useState<RateDataRecord[]>([]);
  const [dateRange, setDateRange] = useState<RangeValue<Moment>>([null, null]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [selectedProperty, setSelectedProperty] = useState<string>('All Properties');
  const [selectedChannels, setSelectedChannels] = useState<string[]>([]); // New state variable for channel filter
  const [showNotification, setShowNotification] = useState(false);
  const [transactionsToProcess, setTransactionsToProcess] = useState(0);
  const [rateNameToProcess, setRateNameToProcess] = useState('');
  const [unprocessedTransactionsCount, setUnprocessedTransactionsCount] = useState(0); // New state variable

  const fetchRateRecords = useCallback(async () => {
    setLoading(true);
    try {
      if (currentUser) {
        const token = await currentUser.getIdToken();
        const endpoint = `${process.env.REACT_APP_BACKEND_URL}/api/v1/rates/data/details`;
        const response = await fetch(endpoint, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          if (isMounted.current) {
            setRateDataRecords(data.rate_data_with_details || []);
            setFilteredRateDataRecords(data.rate_data_with_details || []);
            checkTransactionsToProcess(data.rate_data_with_details || []);

            // Check for unprocessed transactions and set the count
            if (data.rate_data_unprocessed && data.rate_data_unprocessed.length > 0) {
              setUnprocessedTransactions(data.rate_data_unprocessed);
              setUnprocessedTransactionsCount(data.rate_data_unprocessed.length);
              setShowNotification(true);
            } else {
              setShowNotification(false);
            }
          }
        } else {
          notificationController.error({ message: t('error.fetchRateRecords') });
        }
      }
    } catch (error) {
      notificationController.error({ message: t('error.fetchRateRecords') });
    } finally {
      setLoading(false);
    }
  }, [t, currentUser, isMounted]);

  const checkTransactionsToProcess = (data: RateDataRecord[]) => {
    const rateName = 'xxx'; // Replace with the specific rate name you are interested in
    const transactions = data.filter((record) => record.rate_name === rateName);
    if (transactions.length > 0) {
      setTransactionsToProcess(transactions.length);
      setRateNameToProcess(rateName);
      setShowNotification(true);
    } else {
      setShowNotification(false);
    }
  };

  const handleProcessTransactions = async () => {
    try {
      if (currentUser) {
        const token = await currentUser.getIdToken();
        const endpoint = `${process.env.REACT_APP_BACKEND_URL}/api/v1/rates/data/process`;
        const response = await fetch(endpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(unprocessedTransactions),
        });
        if (response.ok) {
          notificationController.success({ message: t('notifications.transactionsProcessed') });
          fetchRateRecords(); // Refresh data after processing
        } else {
          notificationController.error({ message: t('notifications.errorProcessTransactions') });
        }
      }
    } catch (error) {
      notificationController.error({ message: t('notifications.errorProcessTransactions') });
    }
  };

  useEffect(() => {
    fetchRateRecords();
  }, [fetchRateRecords]);

  const handleTableChange = (newPagination: TablePaginationConfig) => {
    setPagination(newPagination);
  };

  const uniqueTransactions = Array.from(
    new Map(filteredRateDataRecords.map((item) => [item.transaction_id, item])).values(),
  );

  const handleTransactionClick = (transaction_id: string) => {
    window.open(`/transaction/${transaction_id}`, '_blank');
  };

  const formatNumber = (num: number | undefined) => {
    return num?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 });
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return '';
    }
    const day = date.getDate();
    const month = date.toLocaleDateString(undefined, { month: 'short' }).toLowerCase();
    const year = date.getFullYear();
    return `${day} ${t(`common.month3Letters.${month}`)} ${year}`;
  };

  const handleDateRangeChange = (dates: RangeValue<Moment>, dateStrings: [string, string]) => {
    setDateRange(dates);
    filterRecords(dates, selectedProperty, selectedChannels);
  };

  const handlePropertyChange = (property: string) => {
    setSelectedProperty(property);
    filterRecords(dateRange, property, selectedChannels);
  };

  const handleChannelChange = (channels: string[]) => {
    setSelectedChannels(channels);
    filterRecords(dateRange, selectedProperty, channels);
  };

  const filterRecords = (dates: RangeValue<Moment>, property: string, channels: string[]) => {
    let filteredData = rateDataRecords;

    if (dates && dates[0] && dates[1]) {
      filteredData = filteredData.filter((record) => {
        const arrivalDate = moment(record.transaction_arrival_date);
        return arrivalDate.isBetween(dates[0], dates[1], 'days', '[]');
      });
    }

    if (property !== 'All Properties') {
      filteredData = filteredData.filter((record) => record.transaction_property_name === property);
    }

    if (channels.length > 0) {
      filteredData = filteredData.filter((record) => channels.includes(record.transaction_category_name));
    }

    setFilteredRateDataRecords(filteredData);
  };

  const exportToExcel = () => {
    const totalRow = calculateTotals();
    const dataToExport = uniqueTransactions.map((transaction) => {
      const currencySymbol =
        rateDataRecords.find((rate) => rate.transaction_id === transaction.transaction_id)?.currency_symbol || '';
      const row: { [key: string]: any } = {
        [t('common.name')]: transaction.transaction_guest_name,
        [t('forms.transactionFormLabels.property')]: transaction.transaction_property_name,
        [t('forms.transactionFormLabels.channel')]: transaction.transaction_category_name,
        [t('forms.transactionFormLabels.arrivalDate')]: formatDate(transaction.transaction_arrival_date),
        [t('forms.transactionFormLabels.departureDate')]: formatDate(transaction.transaction_departure_date),
        [t('forms.transactionFormLabels.nights')]:
          transaction.transaction_total_amount === 0 ? 0 : transaction.transaction_nights,
        [`${t('forms.transactionFormLabels.PayoutAmountIncome')} (${currencySymbol})`]:
          transaction.transaction_total_amount,
      };

      dynamicRateNames.forEach((rateName) => {
        const rateRecord = rateDataRecords.find(
          (rate) => rate.transaction_id === transaction.transaction_id && rate.rate_name === rateName,
        );
        row[`${rateName} (${currencySymbol})`] = rateRecord ? rateRecord.rate_value : 0;
      });

      if (dynamicRateNames.includes('TAKK')) {
        const takkRate =
          rateDataRecords.find(
            (rate) => rate.transaction_id === transaction.transaction_id && rate.rate_name === 'TAKK',
          )?.rate_value || 0;
        row[`${t('forms.transactionFormLabels.taxableAmount')} (${currencySymbol})`] =
          transaction.transaction_host_payment_amount !== null
            ? transaction.transaction_host_payment_amount - takkRate
            : 0;
      }

      return row;
    });

    const totalExportRow: { [key: string]: any } = {
      [t('common.name')]: totalRow.name,
      [t('forms.transactionFormLabels.property')]: totalRow.property,
      [t('forms.transactionFormLabels.channel')]: totalRow.transaction_category_name,
      [t('forms.transactionFormLabels.arrivalDate')]: totalRow.arrivalDate,
      [t('forms.transactionFormLabels.departureDate')]: totalRow.departureDate,
      [t('forms.transactionFormLabels.nights')]: totalRow.nights,
      [`${t('forms.transactionFormLabels.PayoutAmountIncome')} (${totalRow.currencySymbol})`]: totalRow.payoutAmount,
    };

    dynamicRateNames.forEach((rateName) => {
      totalExportRow[`${rateName} (${totalRow.currencySymbol})`] = totalRow[rateName] || 0;
    });

    if (dynamicRateNames.includes('TAKK')) {
      totalExportRow[`${t('forms.transactionFormLabels.taxableAmount')} (${totalRow.currencySymbol})`] =
        totalRow.taxableAmount;
    }

    dataToExport.push(totalExportRow);

    const ws = XLSX.utils.json_to_sheet(dataToExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'TaxAndCommissionData');
    const exportFileName = 'TaxAndCommissionData.xlsx';
    XLSX.writeFile(wb, exportFileName);
  };

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={t('common.searchName')}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
          >
            {t('common.search')}
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small">
            {t('common.reset')}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value: string | number | boolean, record: TableRow) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => (document.querySelector(`input`) as HTMLInputElement)?.select(), 100);
      }
    },
    render: (text: string) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys: string[], confirm: () => void, dataIndex: string) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const dynamicRateNames = Array.from(new Set(rateDataRecords.map((rate) => rate.rate_name)));

  const calculateTotals = () => {
    const totalRow: any = {
      key: 'total',
      name: t('common.total'),
      property: '',
      channel: '',
      arrivalDate: '',
      departureDate: '',
      nights: 0,
      hostPaymentAmount: 0,
      payoutAmount: 0,
      taxableAmount: 0,
      transactionId: '',
      currencySymbol: rateDataRecords[0]?.currency_symbol || '',
    };

    uniqueTransactions.forEach((transaction) => {
      totalRow.nights += transaction.transaction_total_amount === 0 ? 0 : transaction.transaction_nights;
      totalRow.hostPaymentAmount += transaction.transaction_host_payment_amount || 0;
      totalRow.payoutAmount += transaction.transaction_total_amount;

      dynamicRateNames.forEach((rateName) => {
        const rateRecords = rateDataRecords.filter(
          (rate) => rate.transaction_id === transaction.transaction_id && rate.rate_name === rateName,
        );
        totalRow[rateName] = (totalRow[rateName] || 0) + rateRecords.reduce((sum, rate) => sum + rate.rate_value, 0);
      });

      if (dynamicRateNames.includes('TAKK')) {
        const takkRate =
          rateDataRecords.find(
            (rate) => rate.transaction_id === transaction.transaction_id && rate.rate_name === 'TAKK',
          )?.rate_value || 0;
        totalRow.taxableAmount +=
          transaction.transaction_host_payment_amount !== null
            ? transaction.transaction_host_payment_amount - takkRate
            : 0;
      }
    });

    return totalRow;
  };

  const columns: ColumnsType<TableRow> = [
    {
      title: t('common.name'),
      dataIndex: 'name',
      ...getColumnSearchProps('name'),
      render: (text: string, record: TableRow) => <Link to={`/customer/${record.transaction_guest_id}`}>{text}</Link>,
    },
    {
      title: t('forms.transactionFormLabels.property'),
      dataIndex: 'property',
      sorter: (a: TableRow, b: TableRow) => a.property.localeCompare(b.property),
      showSorterTooltip: false,
    },
    {
      title: t('forms.transactionFormLabels.channel'),
      dataIndex: 'channel',
    },
    {
      title: t('forms.transactionFormLabels.arrivalDate'),
      dataIndex: 'arrivalDate',
      sorter: (a: TableRow, b: TableRow) => new Date(a.arrivalDate).getTime() - new Date(b.arrivalDate).getTime(),
      showSorterTooltip: false,
      defaultSortOrder: 'descend',
      render: (date: string) => formatDate(date),
    },
    {
      title: t('forms.transactionFormLabels.departureDate'),
      dataIndex: 'departureDate',
      sorter: (a: TableRow, b: TableRow) => new Date(b.departureDate).getTime() - new Date(a.departureDate).getTime(),
      showSorterTooltip: false,
      render: (date: string) => formatDate(date),
    },
    {
      title: t('forms.transactionFormLabels.nights'),
      dataIndex: 'nights',
      sorter: (a: TableRow, b: TableRow) => a.nights - b.nights,
      showSorterTooltip: false,
      render: (nights: number, record: TableRow) => (record.payoutAmount === 0 ? 0 : nights),
    },
    {
      title: `${t('forms.transactionFormLabels.PayoutAmountIncome')} (${rateDataRecords[0]?.currency_symbol || ''})`,
      dataIndex: 'payoutAmount',
      sorter: (a: TableRow, b: TableRow) => a.payoutAmount - b.payoutAmount,
      render: (amount: number) => formatNumber(amount),
    },
    ...(dynamicRateNames.includes('TAKK')
      ? [
          {
            title: `${t('forms.transactionFormLabels.taxableAmount')} (${rateDataRecords[0]?.currency_symbol || ''})`,
            dataIndex: 'taxableAmount',
            sorter: (a: TableRow, b: TableRow) => a.taxableAmount! - b.taxableAmount!,
            render: (amount: number | undefined) => (amount !== undefined ? formatNumber(amount) : undefined),
          },
        ]
      : []),
    ...dynamicRateNames.map((rateName) => ({
      title: `${rateName} (${rateDataRecords[0]?.currency_symbol || ''})`,
      dataIndex: rateName,
      sorter: (a: TableRow, b: TableRow) => (a[rateName] || 0) - (b[rateName] || 0),
      render: (_: any, record: TableRow) => {
        if (record.key === 'total') {
          return formatNumber(record[rateName] || 0);
        }
        const rateRecord = rateDataRecords.find(
          (rate) => rate.transaction_id === record.transactionId && rate.rate_name === rateName,
        );
        return rateRecord ? formatNumber(rateRecord.rate_value) : undefined;
      },
    })),
    {
      title: t('tables.actions'),
      dataIndex: 'actions',
      render: (_: any, record: TableRow) => {
        if (record.key === 'total') {
          return null; // No action button for the total row
        }
        return (
          <BaseSpace>
            <BaseButton type="ghost" onClick={() => handleTransactionClick(record.transactionId)}>
              {t('tables.open')}
            </BaseButton>
          </BaseSpace>
        );
      },
    },
  ];

  const tableRows = uniqueTransactions.map((transaction) => {
    const currencySymbol =
      rateDataRecords.find((rate) => rate.transaction_id === transaction.transaction_id)?.currency_symbol || '';
    const row: TableRow = {
      key: transaction.transaction_id,
      transaction_guest_id: transaction.transaction_guest_id,
      name: transaction.transaction_guest_name,
      property: transaction.transaction_property_name,
      channel: transaction.transaction_category_name,
      arrivalDate: transaction.transaction_arrival_date,
      departureDate: transaction.transaction_departure_date,
      nights: transaction.transaction_total_amount === 0 ? 0 : transaction.transaction_nights,
      hostPaymentAmount: transaction.transaction_host_payment_amount,
      payoutAmount: transaction.transaction_total_amount,
      transactionId: transaction.transaction_id,
      currencySymbol,
    };

    if (dynamicRateNames.includes('TAKK')) {
      const takkRate =
        rateDataRecords.find((rate) => rate.transaction_id === transaction.transaction_id && rate.rate_name === 'TAKK')
          ?.rate_value || 0;
      row.taxableAmount = row.hostPaymentAmount !== null ? row.hostPaymentAmount - takkRate : undefined;
    }

    dynamicRateNames.forEach((rateName) => {
      const rateRecord = rateDataRecords.find(
        (rate) => rate.transaction_id === transaction.transaction_id && rate.rate_name === rateName,
      );
      row[rateName] = rateRecord ? rateRecord.rate_value : 0;
    });

    return row;
  });

  // Add total row at the beginning of the tableRows array
  const totalRow = calculateTotals();
  tableRows.unshift(totalRow);

  const properties = Array.from(new Set(rateDataRecords.map((record) => record.transaction_property_name)));
  const channels = Array.from(new Set(rateDataRecords.map((record) => record.transaction_category_name)));

  return (
    <>
      {showNotification && (
        <Alert
          message={t('notifications.unprocessedTransactions', {
            count: unprocessedTransactionsCount,
          })}
          type="warning"
          showIcon
          action={
            <Button
              size="small"
              type="default"
              style={{ backgroundColor: '#faad14', borderColor: '#faad14', color: '#fff' }}
              onClick={handleProcessTransactions}
            >
              {t('notifications.processTransactions')}
            </Button>
          }
          style={{ marginBottom: 16 }}
        />
      )}
      <Space style={{ marginBottom: 16, display: 'flex', justifyContent: 'flex-end', flexWrap: 'wrap' }}>
        <Select
          style={{ width: 250 }}
          className="property-select"
          value={selectedProperty}
          onChange={handlePropertyChange}
        >
          <Option value="All Properties">{t('performance-dashboard.selects.all-properties')}</Option>
          {properties.map((property) => (
            <Option key={property} value={property}>
              {property}
            </Option>
          ))}
        </Select>
        <Select
          mode="multiple"
          style={{ width: 250 }}
          className="channel-select"
          placeholder={t('common.selectChannels')}
          value={selectedChannels}
          onChange={handleChannelChange}
        >
          {channels.map((channel) => (
            <Option key={channel} value={channel}>
              {channel}
            </Option>
          ))}
        </Select>
        <RangePicker
          placeholder={[t('common.SearchStartDate'), t('common.SearchEndDate')]}
          value={dateRange}
          onChange={handleDateRangeChange}
          format="YYYY-MM-DD"
        />
        <BaseButton type="primary" onClick={exportToExcel} className="export-button">
          {t('common.export')}
        </BaseButton>
      </Space>
      <BaseTable
        columns={columns}
        dataSource={tableRows}
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
        scroll={{ x: 800 }}
        bordered
        sortDirections={['descend', 'ascend']}
        rowClassName={(record) => (record.key === 'total' ? 'total-row' : '')}
      />
      <style>
        {`
          .total-row {
            background-color: #f0f0f0;
            font-weight: bold;
          }
          @media (max-width: 300px) {
            .property-select, .channel-select, .export-button {
              width: 100% !important;
              margin-bottom: 8px;
            }
            .export-button {
              font-size: 12px;
            }
          }
        `}
      </style>
    </>
  );
};
