import { DownOutlined } from '@ant-design/icons';
import { media } from '@app/styles/themes/constants';
import styled from 'styled-components';
import { HeaderActionWrapper } from '../../../Header.styles';
import { BaseAvatar } from '@app/components/common/BaseAvatar/BaseAvatar';

export const ProfileDropdownHeader = styled(HeaderActionWrapper)`
  justify-content: center; // This centers children horizontally in a flex container
  width: 100%; // Ensure it takes full width if it's not already set to
  margin-bottom: 1rem;
  cursor: pointer;

  @media only screen and ${media.md} {
    border-radius: 50px;
    padding: 0.3125rem 0.2rem;
  }
`;

export const StyledAvatar = styled(BaseAvatar)`
  transition: filter 0.3s ease;
  margin-right: 10px;

  &.hovered {
    filter: brightness(0.8);
  }
`;

export const DisplayName = styled.span`
  transition: color 0.3s ease;

  &.hovered {
    color: #ffb155;
  }
`;

export const AvatarAndNameContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    // Hover styles for the avatar
    & .avatar {
      filter: brightness(0.8); // Example effect, adjust as needed
    }

    // Hover styles for the display name
    & .display-name {
      color: #ffb155; // Example color, adjust as needed
    }
  }
`;

export const DownArrow = styled(DownOutlined)`
  color: var(--text-secondary-color);

  @media only screen and ${media.md} {
    color: var(--text-main-color);
  }
`;
