import React, { useEffect, useState, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { notificationController } from 'controllers/notificationController';
import { useMounted } from '@app/hooks/useMounted';
import { useAuth } from '@app/contexts/AuthContext';
import { Space, Input, Button } from 'antd';
import { PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import moment, { Moment } from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';
import Highlighter from 'react-highlight-words';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import styled from 'styled-components';

export const AddLabelButton = styled.button`
  background-color: darkblue;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  display: flex; // Align icon and text
  align-items: center;
  gap: 8px; // Space between icon and text

  &:hover {
    background-color: #4f6fad; /* Lighter shade of dark blue. Adjust the color as needed */
  }
`;

const initialPagination: TablePaginationConfig = {
  current: 1,
  pageSize: 10,
};

interface CustomerDataRecord {
  lessee_id: string;
  lessee: string;
  phone: string;
  total_reservations: number;
  total_cancelled_reservations: number;
  total_stayed_nights: number;
  total_cancelled_nights: number;
  total_revenue: number;
  currency_symbol: string;
}

interface TableRow {
  key: string;
  name: string;
  phone: string;
  totalReservations: number;
  totalCancelledReservations: number;
  totalStayedNights: number;
  totalCancelledNights: number;
  totalRevenue: number;
  currencySymbol: string;
  [key: string]: any;
}

export const CustomersTable: React.FC = () => {
  const [customerDataRecords, setCustomerDataRecords] = useState<CustomerDataRecord[]>([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isMounted } = useMounted();
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState<TablePaginationConfig>(initialPagination);
  const [filteredCustomerDataRecords, setFilteredCustomerDataRecords] = useState<CustomerDataRecord[]>([]);
  const [dateRange, setDateRange] = useState<RangeValue<Moment>>([null, null]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');

  const fetchRateRecords = useCallback(async () => {
    setLoading(true);
    try {
      if (currentUser) {
        const token = await currentUser.getIdToken();
        const endpoint = `${process.env.REACT_APP_BACKEND_URL}/api/v1/lessee/data/details`;
        const response = await fetch(endpoint, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          if (isMounted.current) {
            setCustomerDataRecords(data || []);
            setFilteredCustomerDataRecords(data || []);
          }
        } else {
          notificationController.error({ message: t('error.fetchRateRecords') });
        }
      }
    } catch (error) {
      notificationController.error({ message: t('error.fetchRateRecords') });
    } finally {
      setLoading(false);
    }
  }, [t, currentUser, isMounted]);

  useEffect(() => {
    fetchRateRecords();
  }, [fetchRateRecords]);

  const handleTableChange = (newPagination: TablePaginationConfig) => {
    setPagination(newPagination);
  };

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={t('common.searchName')}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
          >
            {t('common.search')}
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small">
            {t('common.reset')}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value: string | number | boolean, record: TableRow) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes((value as string).toLowerCase())
        : false,
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => (document.querySelector(`input`) as HTMLInputElement)?.select(), 100);
      }
    },
    render: (text: string) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys: string[], confirm: () => void, dataIndex: string) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const formatRevenue = (value: number) => {
    return value % 1 !== 0 ? value.toFixed(2) : value.toString();
  };

  const columns: ColumnsType<TableRow> = [
    {
      title: t('common.name'),
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name'),
      render: (text: string, record: TableRow) => <Link to={`/customer/${record.lessee_id}`}>{text}</Link>,
    },
    {
      title: t('common.phone'),
      dataIndex: 'phone',
      key: 'phone',
      ...getColumnSearchProps('phone'),
    },
    {
      title: t('common.totalReservations'),
      dataIndex: 'totalReservations',
      key: 'totalReservations',
      sorter: (a, b) => a.totalReservations - b.totalReservations,
    },
    {
      title: t('common.totalCancelledReservations'),
      dataIndex: 'totalCancelledReservations',
      key: 'totalCancelledReservations',
      sorter: (a, b) => a.totalCancelledReservations - b.totalCancelledReservations,
    },
    {
      title: t('common.totalStayedNights'),
      dataIndex: 'totalStayedNights',
      key: 'totalStayedNights',
      sorter: (a, b) => a.totalStayedNights - b.totalStayedNights,
    },
    {
      title: t('common.totalCancelledNights'),
      dataIndex: 'totalCancelledNights',
      key: 'totalCancelledNights',
      sorter: (a, b) => a.totalCancelledNights - b.totalCancelledNights,
    },
    {
      title: `${t('common.totalRevenue')} (${customerDataRecords[0]?.currency_symbol || ''})`,
      dataIndex: 'totalRevenue',
      key: 'totalRevenue',
      sorter: (a, b) => a.totalRevenue - b.totalRevenue,
      render: (text: number) => formatRevenue(text), // Ensuring conditional decimal formatting
    },
  ];

  const tableRows = customerDataRecords.map((customer) => {
    return {
      key: customer.lessee,
      lessee_id: customer.lessee_id,
      name: customer.lessee,
      phone: customer.phone,
      totalReservations: customer.total_reservations,
      totalCancelledReservations: customer.total_cancelled_reservations,
      totalStayedNights: customer.total_stayed_nights,
      totalCancelledNights: customer.total_cancelled_nights,
      totalRevenue: customer.total_revenue,
      currencySymbol: customer.currency_symbol,
    };
  });

  const handleAddCustomer = () => {
    navigate('/customer/new'); // Adjust the path as necessary
  };

  return (
    <>
      <Space style={{ marginBottom: 16, display: 'flex', justifyContent: 'flex-end', flexWrap: 'wrap' }}>
        {/* <BaseButton type="primary" onClick={handleAddCustomer} className="export-button">
          {t('common.addCustomer')}
        </BaseButton> */}
        <AddLabelButton onClick={handleAddCustomer}>
          <PlusCircleOutlined style={{ color: 'white' }} />
          {t('common.addNewCustomer')}
        </AddLabelButton>
      </Space>
      <BaseTable
        columns={columns}
        dataSource={tableRows}
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
        scroll={{ x: 800 }}
        bordered
        sortDirections={['descend', 'ascend']}
      />
      <style>
        {`
          .total-row {
            background-color: #f0f0f0;
            font-weight: bold;
          }
          @media (max-width: 300px) {
            .property-select, .channel-select, .export-button {
              width: 100% !important;
              margin-bottom: 8px;
            }
            .export-button {
              font-size: 12px;
            }
          }
        `}
      </style>
    </>
  );
};
