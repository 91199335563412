import { User } from 'firebase/auth';

export interface Property {
  id: string;
  name: string;
  user_id: string;
  creation_date: string;
  last_updated_date: string;
  property_type_id: string;
  country_id: string;
  currency_id: string;
}

export const getProperties = async (currentUser: User): Promise<Property[]> => {
  try {
    const token = await currentUser.getIdToken();
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/property?property_type=STR`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    return [];
  }
};
