import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseCard } from '../../common/BaseCard/BaseCard';
import { PerformanceChart } from './PerformanceChart';
import { PerformanceData, IncomeExpensesEntry, TransactionTimelineData } from 'interfaces/interfaces';
import styled from 'styled-components';

interface PerformanceCardProps {
  data: PerformanceData | TransactionTimelineData | null;
  setSelectedFocusStartDate: (date: string) => void;
  setSelectedFocusEndDate: (date: string) => void;
}

export const PerformanceCard: React.FC<PerformanceCardProps> = ({
  data,
  setSelectedFocusStartDate,
  setSelectedFocusEndDate,
}) => {
  const { t } = useTranslation();

  const [dataIds, setDataIds] = useState<string[]>([]);
  const [dataIncome, setDataIncome] = useState<number[]>([]);
  const [dataExpenses, setDataExpenses] = useState<number[]>([]);
  const [dataIncomeExpensesAllEntries, setdataIncomeExpensesAllEntries] = useState<IncomeExpensesEntry[]>([]);
  const [dataCurrency, setDataCurrency] = useState<string>('');

  // When 'data' changes, update state for IDs, incomes, and expenses
  useEffect(() => {
    if (data && data.income_expenses_data) {
      const ids: string[] = data.income_expenses_data.map((item) => item.id);
      const incomes: number[] = data.income_expenses_data.map((item) => item.income);
      const expenses: number[] = data.income_expenses_data.map((item) => -item.expenses);
      const IncomeExpensesAllEntries: IncomeExpensesEntry[] = data.income_expenses_data;
      const currency = data.currency.symbol;

      setDataIds(ids);
      setDataIncome(incomes);
      setDataExpenses(expenses);
      setdataIncomeExpensesAllEntries(IncomeExpensesAllEntries);
      setDataCurrency(currency);
    }
  }, [data]); // Dependency array ensures this runs when `data` changes

  return (
    <PerformanceCardStyled id="activity" title={t('performance-dashboard.performance.title')} padding={0}>
      <PerformanceChart
        dataIds={dataIds || []}
        dataIncome={dataIncome || []}
        dataExpenses={dataExpenses || []}
        dataIncomeExpensesAllEntries={dataIncomeExpensesAllEntries || []} // Pass the income_expenses_data containing start_date and end_date
        dataCurrency={dataCurrency}
        setSelectedFocusStartDate={setSelectedFocusStartDate}
        setSelectedFocusEndDate={setSelectedFocusEndDate}
      />
    </PerformanceCardStyled>
  );
};

const PerformanceCardStyled = styled(BaseCard)`
  width: 100%;
  height: 100%;
`;
