import React from 'react';
import { useTranslation } from 'react-i18next';
import { accountNavData } from '@app/constants/accountNavData';
import { useLocation, useNavigate } from 'react-router-dom';
import * as S from './AccountNav.styles';
import { FloatingNewTransactionButton } from '@app/components/common/icons/FloatingNewTransactionButton';
import { useResponsive } from '@app/hooks/useResponsive';
import { LogoutOutlined } from '@ant-design/icons';
import { useAuth } from '@app/contexts/AuthContext';

export const AccountNav: React.FC = () => {
  const { t } = useTranslation();
  const { isTablet } = useResponsive();
  const { userDetails } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();

  const filteredAccountNavData =
    userDetails?.role === 'Standard User' ? accountNavData : accountNavData.filter((item) => item.id !== 7); // Remove item with id 6 (Billing)

  return (
    <>
      <S.Wrapper>
        {filteredAccountNavData.map((item) => (
          <React.Fragment key={item.id}>
            <S.Btn
              icon={item.icon}
              type="text"
              color={item.color as 'success' | 'error' | 'warning' | 'primary'} // Assert correct type if it's controlled and known
              onClick={() => navigate(item.href)}
              $isActive={`/profile/${item.href}` === location.pathname}
            >
              {t(item.name)}
            </S.Btn>
          </React.Fragment>
        ))}
        {!isTablet && (
          <S.Btn
            icon={<LogoutOutlined />}
            type="text"
            color={'primary'}
            onClick={() => navigate('/logout')}
            $isActive={`/logout` === location.pathname}
          >
            {t('header.logout')}
          </S.Btn>
        )}
      </S.Wrapper>
      <FloatingNewTransactionButton />
    </>
  );
};
