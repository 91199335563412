import React from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './Help.styles';
import Breadcrumbs from './Breadcrumbs';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledLink = styled(Link)`
  display: block;
  padding: 10px 15px;
  text-decoration: none;
  color: #333;
  border-radius: 5px;
  position: relative;
  padding-left: 25px;

  &:before {
    content: '•';
    position: absolute;
    left: 10px;
    color: #333;
  }

  &:hover {
    color: #0073e6;
  }
`;

export const transactionHelpCenterItems = [
  {
    to: '/help/how-to-get-platform-total-amount',
    textKey: 'helpCenter.howToGetTheTotalAmount.breadcrumb',
  },
  {
    to: '/help/how-to-get-platform-payout-amount',
    textKey: 'helpCenter.howToGetThePayoutAmount.breadcrumb',
  },
];

const HelpCenterTransactionsPage: React.FC = () => {
  const { t } = useTranslation();

  const breadcrumbItems = [
    { label: 'breadcrumbs.help', path: '/help' },
    { label: 'helpCenter.transactions', path: '/help/transactions' },
  ];

  return (
    <S.Wrapper>
      <S.BreadcrumbWrapper>
        <Breadcrumbs items={breadcrumbItems} />
      </S.BreadcrumbWrapper>
      <S.ContentWrapper>
        <S.MainContent>
          <S.Title>{t('helpCenter.transactionsTitle')}</S.Title>
          {transactionHelpCenterItems.map((link, index) => (
            <StyledLink key={index} to={link.to}>
              {t(link.textKey)}
            </StyledLink>
          ))}
        </S.MainContent>
      </S.ContentWrapper>
    </S.Wrapper>
  );
};

export default HelpCenterTransactionsPage;
