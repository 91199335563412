import React, { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import GlobalStyle from './styles/GlobalStyle';
import 'typeface-montserrat';
import 'typeface-lato';
import { AppRouter } from './components/router/AppRouter';
import { useAutoNightMode } from './hooks/useAutoNightMode';
import { usePWA } from './hooks/usePWA';
import { useThemeWatcher } from './hooks/useThemeWatcher';
import { useAppSelector } from './hooks/reduxHooks';
import { themeObject } from './styles/themes/themeVariables';
import { useTranslation } from 'react-i18next';

const App: React.FC = () => {
  const theme = useAppSelector((state) => state.theme.theme);
  const { i18n } = useTranslation();
  const defaultLanguage = 'en'; // Define your default language

  useEffect(() => {
    const savedLanguage = localStorage.getItem('selectedLanguage');
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    } else {
      i18n.changeLanguage(defaultLanguage); // Fallback to default language
    }
  }, [i18n, defaultLanguage]);

  usePWA();

  useAutoNightMode();

  useThemeWatcher();

  return (
    <>
      <meta name="theme-color" content={themeObject[theme].primary} />
      <GlobalStyle />
      <HelmetProvider>
        {/* <ConfigProvider locale={language === 'en' ? enUS : deDe}> */}
        {/* <AuthProvider> */}
        <AppRouter />
        {/* </AuthProvider> */}
        {/* </ConfigProvider> */}
      </HelmetProvider>
    </>
  );
};

export default App;
