import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { BlockOutlined } from '@ant-design/icons';
import * as S from './SiderMenu.styles';
import { sidebarNavigation as staticSidebarNavigation, SidebarNavigationItem } from '../sidebarNavigation';
import { ProfileDropdown } from '@app/components/header/components/profileDropdown/ProfileDropdown/ProfileDropdown';
import { useAuth } from '@app/contexts/AuthContext';

interface SiderContentProps {
  setCollapsed: (isCollapsed: boolean) => void;
}

const SiderMenu: React.FC<SiderContentProps> = ({ setCollapsed }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [sidebarNavigation, setSidebarNavigation] = useState<SidebarNavigationItem[]>([]);
  const { currentUser } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      if (currentUser) {
        try {
          const token = await currentUser.getIdToken(); // Wait for the token promise to resolve
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/details`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          });
          const data = await response.json();
          const updatedNavigation = [...staticSidebarNavigation];
          if (['Super Admin', 'Admin'].includes(data.role)) {
            updatedNavigation.push({
              title: t('common.inviteUsers'),
              key: 'invite-users',
              url: '/invite-users',
              icon: <BlockOutlined />,
            });
          }
          setSidebarNavigation(
            updatedNavigation.map((nav) => ({
              ...nav,
              title: t(nav.title),
              key: nav.key,
              url: nav.url,
              icon: nav.icon,
            })),
          );
        } catch (error) {
          console.log(error);
        }
      }
    };

    fetchData();
  }, [currentUser, t]);

  const sidebarNavFlat = sidebarNavigation.reduce(
    (result: SidebarNavigationItem[], current) =>
      result.concat(current.children && current.children.length > 0 ? current.children : current),
    [],
  );

  const currentMenuItem = sidebarNavFlat.find(({ url }) => url === location.pathname);
  const defaultSelectedKeys = currentMenuItem ? [currentMenuItem.key] : [];
  const openedSubmenu = sidebarNavigation.find(({ children }) =>
    children?.some(({ url }) => url === location.pathname),
  );
  const defaultOpenKeys = openedSubmenu ? [openedSubmenu.key] : [];

  return (
    <>
      <S.SidebarFlexContainer>
        <S.Menu
          mode="inline"
          defaultSelectedKeys={defaultSelectedKeys}
          defaultOpenKeys={defaultOpenKeys}
          onClick={() => setCollapsed(true)}
          items={sidebarNavigation.map((nav) => {
            const isSubMenu = nav.children ? nav.children.length > 0 : false;

            return {
              key: nav.key,
              title: t(nav.title),
              label: isSubMenu ? t(nav.title) : <Link to={nav.url || ''}>{t(nav.title)}</Link>,
              icon: nav.icon,
              children: isSubMenu
                ? nav.children?.map((childNav) => ({
                    key: childNav.key,
                    label: <Link to={childNav.url || ''}>{t(childNav.title)}</Link>,
                    title: t(childNav.title),
                  }))
                : undefined, // Return undefined if nav.children is null or undefined
            };
          })}
        />
        <ProfileDropdown />
      </S.SidebarFlexContainer>
    </>
  );
};

export default SiderMenu;
