import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// no lazy loading for auth pages to avoid flickering
const AuthLayout = React.lazy(() => import('@app/components/layouts/AuthLayout/AuthLayout'));
import LoginPage from '@app/pages/LoginPage';
import SignUpPage from '@app/pages/SignUpPage';
import ForgotPasswordPage from '@app/pages/ForgotPasswordPage';
import SecurityCodePage from '@app/pages/SecurityCodePage';
import NewPasswordPage from '@app/pages/NewPasswordPage';
import LockPage from '@app/pages/LockPage';

import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';
import ProfileLayout from '@app/components/profile/ProfileLayout';
import RequireAuth from '@app/components/router/RequireAuth';
import { withLoading } from '@app/hocs/withLoading.hoc';

import { AuthProvider } from '@app/contexts/AuthContext';
import { UserProvider } from '@app/utils/UserSignUpContext.js';
import FinancialOverviewDashboardPage from '@app/pages/DashboardPages/FinancialOverviewDashboardPage';
import TransactionsTimelinePage from '@app/pages/TransactionsTimelinePage';
import PerformanceDashboardPage from '@app/pages/DashboardPages/PerformanceDashboardPage';
import TaxAndCommissionRatesPage from '@app/pages/TaxAndCommissionRatesPage';
import CustomerInfoPage from '@app/pages/CustomerInfoPage';
import CustomersPage from '@app/pages/CustomersPage';
import CustomerFormPage from '@app/pages/CustomerFormPage';
import HelpCenterPage from '@app/pages/HelpPages/HelpCenterPage';
import HelpCenterTransactionsPage from '@app/pages/HelpPages/HelpCenterTransactionsPage';
import GetTotalAmountHelpPage from '@app/pages/HelpPages/GetTotalAmountHelpPage';
import GetPayoutAmountHelpPage from '@app/pages/HelpPages/GetPayoutAmountHelpPage';

const Error404Page = React.lazy(() => import('@app/pages/Error404Page'));
const TransactionFormPage = React.lazy(() => import('@app/pages/TransactionFormPage'));
const PropertyFormPage = React.lazy(() => import('@app/pages/PropertyFormPage'));
const CategoryFormPage = React.lazy(() => import('@app/pages/CategoryFormPage'));
const LabelFormPage = React.lazy(() => import('@app/pages/LabelFormPage'));
const AccountSetupPage = React.lazy(() => import('@app/pages/AccountSetupPage'));
const InviteUsersPage = React.lazy(() => import('@app/pages/InviteUsersPage'));
const PersonalInfoPage = React.lazy(() => import('@app/pages/PersonalInfoPage'));
const BulkImportPage = React.lazy(() => import('@app/pages/BulkImportPage'));
const PaymentsPage = React.lazy(() => import('@app/pages/PaymentsPage'));
const PropertiesPage = React.lazy(() => import('@app/pages/PropertiesPage'));
const CategoriesPage = React.lazy(() => import('@app/pages/CategoriesPage'));
const LabelsPage = React.lazy(() => import('@app/pages/LabelsPage'));
const AdvancedRatesPage = React.lazy(() => import('@app/pages/AdvancedRatesPage'));
const Logout = React.lazy(() => import('./Logout'));

export const FINANCIAL_OVERVIEW = '/';
export const TIMELINE = '/timeline';
export const PERFORMANCE = '/performance';
export const TAX_AND_COMMISSION_RATES = '/tax-commission-rates';
export const CUSTOMER_NEW = '/customer/new';
export const CUSTOMER_EDIT = '/customer/:customerId?/edit';
export const CUSTOMER = '/customer/:customerId?';
export const CUSTOMERS = '/customers';
export const TRANSACTION = '/transaction/:transactionId?';
export const PROPERTY = '/property/:propertyId?';
export const CATEGORY = '/category/:categoryId?';
export const LABEL = '/label/:labelId?';
export const ACCOUNT_SETUP = '/account-setup';
export const INVITE_USERS = '/invite-users';
export const BILLING = '/account/billing';

export const HELP_CENTER_HOMEPAGE = '/help';
export const HELP_CENTER_TRANSACTIONS = '/help/transactions';
export const HELP_GET_PLATFORM_TOTAL_AMOUNT = '/help/how-to-get-platform-total-amount';
export const HELP_GET_PLATFORM_PAYOUT_AMOUNT = '/help/how-to-get-platform-payout-amount';

const FinancialOverviewDashboard = withLoading(FinancialOverviewDashboardPage);
const TransactionsTimeline = withLoading(TransactionsTimelinePage);
const PerformanceDashboard = withLoading(PerformanceDashboardPage);
const TaxAndCommissionRatesTable = withLoading(TaxAndCommissionRatesPage);
const CustomerInfoTable = withLoading(CustomerInfoPage);
const CustomersTable = withLoading(CustomersPage);
const CustomerForm = withLoading(CustomerFormPage);
const TransactionForm = withLoading(TransactionFormPage);
const PropertyForm = withLoading(PropertyFormPage);
const CategoryForm = withLoading(CategoryFormPage);
const LabelForm = withLoading(LabelFormPage);

//HELP
const HelpCenter = withLoading(HelpCenterPage);
const HelpCenterTransactions = withLoading(HelpCenterTransactionsPage);
const GetTotalAmountHelp = withLoading(GetTotalAmountHelpPage);
const GetPayoutAmountHelp = withLoading(GetPayoutAmountHelpPage);

const Error404 = withLoading(Error404Page);

// Profile
const AccountSetup = withLoading(AccountSetupPage);
const PersonalInfo = withLoading(PersonalInfoPage);
const BulkImport = withLoading(BulkImportPage);
const Payments = withLoading(PaymentsPage);
const Properties = withLoading(PropertiesPage);
const Categories = withLoading(CategoriesPage);
const Labels = withLoading(LabelsPage);
const AdvancedRates = withLoading(AdvancedRatesPage);
const InviteUsers = withLoading(InviteUsersPage);

const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);

export const AppRouter: React.FC = () => {
  const protectedLayout = (
    <AuthProvider>
      <RequireAuth>
        <MainLayout />
      </RequireAuth>
    </AuthProvider>
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route path={FINANCIAL_OVERVIEW} element={protectedLayout}>
          <Route index element={<FinancialOverviewDashboard />} />
          <Route path={TIMELINE} element={<TransactionsTimeline />} />
          <Route path={PERFORMANCE} element={<PerformanceDashboard />} />
          <Route path={TAX_AND_COMMISSION_RATES} element={<TaxAndCommissionRatesTable />} />
          <Route path={CUSTOMER_NEW} element={<CustomerForm />} />
          <Route path={CUSTOMER} element={<CustomerInfoTable />} />
          <Route path={CUSTOMERS} element={<CustomersTable />} />
          <Route path={CUSTOMER_EDIT} element={<CustomerForm />} />
          <Route path={TRANSACTION} element={<TransactionForm />} />
          <Route path={PROPERTY} element={<PropertyForm />} />
          <Route path={CATEGORY} element={<CategoryForm />} />
          <Route path={LABEL} element={<LabelForm />} />
          <Route path={ACCOUNT_SETUP} element={<AccountSetup />} />
          <Route path={INVITE_USERS} element={<InviteUsers />} />
          <Route path={HELP_CENTER_HOMEPAGE} element={<HelpCenter />} />
          <Route path={HELP_CENTER_TRANSACTIONS} element={<HelpCenterTransactions />} />
          <Route path={HELP_GET_PLATFORM_TOTAL_AMOUNT} element={<GetTotalAmountHelp />} />
          <Route path={HELP_GET_PLATFORM_PAYOUT_AMOUNT} element={<GetPayoutAmountHelp />} />
          <Route path="account" element={<ProfileLayout />}>
            <Route path="personal-info" element={<PersonalInfo />} />
            <Route path="properties" element={<Properties />} />
            <Route path="categories" element={<Categories />} />
            <Route path="labels" element={<Labels />} />
            <Route path="advanced-rates" element={<AdvancedRates />} />
            <Route path="bulk-import" element={<BulkImport />} />
            <Route path="billing" element={<Payments />} />
          </Route>
        </Route>
        <Route
          path="/auth"
          element={
            <React.Suspense fallback={<div>Loading...</div>}>
              <UserProvider>
                <AuthLayoutFallback />
              </UserProvider>
            </React.Suspense>
          }
        >
          <Route path="login" element={<LoginPage />} />
          <Route path="sign-up/:lang?" element={<SignUpPage />} />
          <Route
            path="lock"
            element={
              <RequireAuth>
                <LockPage />
              </RequireAuth>
            }
          />
          <Route path="forgot-password" element={<ForgotPasswordPage />} />
          <Route path="email-verification" element={<SecurityCodePage />} />
          <Route path="new-password" element={<NewPasswordPage />} />
        </Route>
        <Route path="/logout" element={<LogoutFallback />} />
        {/* Catch-all route for 404 Page Not Found */}
        <Route path="*" element={<Error404 />} />
      </Routes>
    </BrowserRouter>
  );
};
