import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseCard } from '../../common/BaseCard/BaseCard';
import { PerformanceSTRMetricsData } from 'interfaces/interfaces';
import styled from 'styled-components';
import { OccupancyRateChart } from './OccupancyRateChart';

interface OccupancyRateCardProps {
  data: PerformanceSTRMetricsData | null;
  selectedDatePeriodBreakDown: string | undefined;
  selectedDateIndex: string | number | null;
  selectedChartZoomRange: {
    startValue: number | null;
    endValue: number | null;
  };
  onInteraction: (index: string | number, bar_id: string, initialBarsToShow: number, dataLength: number) => void;
}

export const OccupancyRateCard: React.FC<OccupancyRateCardProps> = ({
  data,
  selectedDatePeriodBreakDown,
  selectedDateIndex,
  selectedChartZoomRange,
  onInteraction,
}) => {
  const { t } = useTranslation();

  const [dataIds, setDataIds] = useState<string[]>([]);
  const [dataOccupancyRate, setDataOccupancyRate] = useState<number[]>([]);
  const [dataOccupancyRateLastYear, setDataOccupancyRateLastYear] = useState<number[]>([]);

  useEffect(() => {
    if (data && data.occupancy_rate_data) {
      const ids: string[] = data.occupancy_rate_data.map((item) => item.id);
      const occupancyRates: number[] = data.occupancy_rate_data.map((item) => item.occupancy_rate);

      setDataIds(ids);
      setDataOccupancyRate(occupancyRates);

      if (selectedDatePeriodBreakDown === 'month') {
        // Derive last year's data
        const lastYearRates: number[] = data.occupancy_rate_data.map((item) => {
          const lastYearDate = new Date(item.start_date);
          lastYearDate.setFullYear(lastYearDate.getFullYear() - 1);

          // Preserve the original timezone offset
          const timezoneOffset = new Date(item.start_date).getTimezoneOffset();
          const lastYearDateWithTimezone = new Date(lastYearDate.getTime() - timezoneOffset * 60000);
          const lastYearId = lastYearDateWithTimezone.toISOString().slice(0, 7);

          const lastYearItem = data.occupancy_rate_data.find((i) => i.id === lastYearId);
          return lastYearItem ? lastYearItem.occupancy_rate : 0;
        });

        setDataOccupancyRateLastYear(lastYearRates);
      } else {
        setDataOccupancyRateLastYear([]); // Clear last year's data if not monthly breakdown
      }
    }
  }, [data, selectedDatePeriodBreakDown]);

  return (
    <OccupancyRateCardStyled id="activity" title={t('performance-dashboard.occupancy-rate.title')} padding={0}>
      <OccupancyRateChart
        dataIds={dataIds || []}
        dataOccupancyRate={dataOccupancyRate || []}
        dataOccupancyRateLastYear={dataOccupancyRateLastYear || []}
        selectedDatePeriodBreakDown={selectedDatePeriodBreakDown}
        selectedDateIndex={selectedDateIndex}
        selectedChartZoomRange={selectedChartZoomRange}
        onInteraction={onInteraction}
      />
    </OccupancyRateCardStyled>
  );
};

const OccupancyRateCardStyled = styled(BaseCard)`
  width: 100%;
  height: 100%;
`;
