import React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomerInfoTables } from '@app/components/tables/Tables/CustomerInfoTables';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useParams } from 'react-router-dom';

const CustomerInfoPage: React.FC = () => {
  const { customerId } = useParams(); // Accessing the parameter
  const { t } = useTranslation();
  return (
    <>
      <PageTitle>{t('common.customerInfo')}</PageTitle>
      <CustomerInfoTables customerId={customerId} />
    </>
  );
};

export default CustomerInfoPage;
