import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useResponsive } from '@app/hooks/useResponsive';
import * as S from './DashboardPage.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { PropertiesSelect } from '@app/components/common/selects/PropertiesSelect/PropertiesSelect';
import { DatesSelect } from '@app/components/common/selects/DateSelect/DateSelect';
import { PerformanceCard } from '@app/components/performance-dashboard/performanceCard/PerformanceCard';
import { CategoryCard } from '@app/components/performance-dashboard/categoryCard/CategoryCard';
import { LabelCard } from '@app/components/performance-dashboard/labelCard/LabelCard';
import { PerformanceData } from 'interfaces/interfaces';
import { dateSelects as configDateSelects } from 'constants/config/dateSelects';
import { useAuth } from '@app/contexts/AuthContext';
import { BaseTabs } from '@app/components/common/BaseTabs/BaseTabs';
import { ReactComponent as DollarUpIcon } from '@app/assets/images/dollar-up-icon.svg';
import { ReactComponent as DollarDownIcon } from '@app/assets/images/dollar-down-icon.svg';
import { getThisMonthStartDate, getThisMonthEndDate, formatDateRange } from '@app/utils/DateUtils';
import styled from 'styled-components';
import { BaseBreadcrumb } from '@app/components/common/BaseBreadcrumb/BaseBreadcrumb';
import { HomeOutlined } from '@ant-design/icons';
import { FloatingNewTransactionButton } from '@app/components/common/icons/FloatingNewTransactionButton';

const StyledDollarUpIcon = styled(DollarUpIcon)`
  margin-right: 8px;
  height: 20px; // Set the size of your icon
  width: 20px; // Set the size of your icon
  color: var(--primary-color); // Adjust the color if your SVG supports CSS color property
`;

const StyledDollarDownIcon = styled(DollarDownIcon)`
  margin-right: 8px;
  height: 20px; // Set the size of your icon
  width: 20px; // Set the size of your icon
  color: var(--primary-color); // Adjust the color if your SVG supports CSS color property
`;

const StyledPerformanceName = styled.span`
  font-size: 20px;
  padding: 8px;
`;

const StyledCard = styled(S.Card)`
  margin-bottom: 0; // Remove any margin-bottom
  .ant-card-body {
    padding-top: 0; // Remove padding-top from the card body
  }
`;

const DateRange = styled.span`
  font-size: 15px;
  color: gray;
  margin-left: 3rem;
`;

const CategoryTitle = styled.span`
  font-size: 21px; // Larger font size for the category title
`;

const FinancialOverviewDashboardPage: React.FC = () => {
  const { isDesktop } = useResponsive();
  const { t } = useTranslation();
  const { currentUser } = useAuth();
  const [selectedProperty, setSelectedProperty] = useState<string | undefined>(undefined);
  const [selectedDatePeriodBreakDown, setSelectedDatePeriodBreakDown] = useState<string | undefined>('month');
  const [performanceData, setPerformanceData] = useState<PerformanceData | null>(null);
  const [selectedFocusStartDate, setSelectedFocusStartDate] = useState<string>(getThisMonthStartDate());
  const [selectedFocusEndDate, setSelectedFocusEndDate] = useState<string>(getThisMonthEndDate());

  const handlePropertySelect = (value: string | undefined) => {
    setSelectedProperty(value);
  };

  // Function to be called when a new DatePeriodBreakDown is selected
  const handleDatePeriodBreakDownSelect = (value: unknown) => {
    const selectedValue = value as string;

    // Find the dateSelect object by its name (value) to access its id
    const selectedDateSelect = configDateSelects.find((dateSelect) => dateSelect.name === selectedValue);

    if (selectedDateSelect) {
      setSelectedDatePeriodBreakDown(selectedDateSelect.id);
    } else {
      console.error('Selected date period not found in config');
      // Handle the case where the selected value doesn't match any dateSelect
    }
  };

  useEffect(() => {
    if (currentUser && (selectedProperty || selectedDatePeriodBreakDown)) {
      // Initialize params as an empty object, then only add properties if they are defined
      const params: Record<string, string> = {};

      if (selectedProperty) params.property_id = selectedProperty;
      if (selectedDatePeriodBreakDown) params.date_period_breakdown = selectedDatePeriodBreakDown;

      // Assuming 'focus_start_date' and 'focus_end_date' are always defined as strings for this example
      if (selectedFocusStartDate) params.focus_start_date = selectedFocusStartDate;
      if (selectedFocusEndDate) params.focus_end_date = selectedFocusEndDate;
      params.data_view_type = 'all';

      // Now params is guaranteed to only contain string values
      const queryParams = new URLSearchParams(params).toString();

      // Function to fetch data from the backend
      const fetchData = async () => {
        try {
          const token = await currentUser.getIdToken(); // Wait for the token promise to resolve
          const response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/api/v1/performance/income-expenses?${queryParams}`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
              },
            },
          );
          const data = await response.json();
          setPerformanceData(data); // Update state with fetched data
        } catch (error) {
          console.log(error);
        }
      };

      fetchData();
    }
  }, [selectedProperty, selectedDatePeriodBreakDown, currentUser, selectedFocusStartDate, selectedFocusEndDate]);

  const monthNames = [
    t('common.month3Letters.jan'),
    t('common.month3Letters.feb'),
    t('common.month3Letters.mar'),
    t('common.month3Letters.apr'),
    t('common.month3Letters.may'),
    t('common.month3Letters.jun'),
    t('common.month3Letters.jul'),
    t('common.month3Letters.aug'),
    t('common.month3Letters.sep'),
    t('common.month3Letters.oct'),
    t('common.month3Letters.nov'),
    t('common.month3Letters.dec'),
  ];

  const desktopLayout = (
    <BaseRow>
      <S.LeftSideCol xl={25} xxl={25}>
        <BaseRow gutter={[10, 10]}>
          <BaseCol span={24} style={{ display: 'flex', justifyContent: 'center' }}>
            <BaseRow gutter={[5, 5]}>
              <PropertiesSelect
                onPropertySelect={handlePropertySelect} // Assume PropertiesSelect now has a prop for this
                itemStyle={{ fontSize: '14px', cursor: 'pointer', padding: '10px' }}
              />
              <DatesSelect
                onDatePeriodBreakDownSelect={handleDatePeriodBreakDownSelect}
                itemStyle={{ fontSize: '14px', cursor: 'pointer', padding: '10px' }}
              />
            </BaseRow>
          </BaseCol>
          <BaseCol span={24} style={{ display: 'flex', justifyContent: 'center' }}>
            <BaseRow gutter={[5, 5]}>
              <BaseBreadcrumb style={{ padding: '0.1rem' }}>
                <BaseBreadcrumb.Item>
                  <HomeOutlined style={{ fontSize: '20px' }} />
                  <StyledPerformanceName>
                    {performanceData?.property_name || t('performance-dashboard.selects.all-properties')}
                  </StyledPerformanceName>
                </BaseBreadcrumb.Item>
              </BaseBreadcrumb>
            </BaseRow>
          </BaseCol>
          <BaseCol id="performance" xl={24} xxl={24}>
            <PerformanceCard
              data={performanceData}
              setSelectedFocusStartDate={setSelectedFocusStartDate}
              setSelectedFocusEndDate={setSelectedFocusEndDate}
            />
          </BaseCol>
          <StyledCard
            title={
              <>
                <CategoryTitle>{t('performance-dashboard.category.title')}</CategoryTitle>
                <DateRange>{formatDateRange(selectedFocusStartDate, selectedFocusEndDate, monthNames)}</DateRange>
              </>
            }
          >
            <BaseTabs
              defaultActiveKey="1"
              items={[
                {
                  key: '1',
                  label: (
                    <span>
                      <StyledDollarUpIcon />
                      {t('performance-dashboard.chart.income')}
                    </span>
                  ),
                  children: <CategoryCard data={performanceData} category_type="Income" />,
                },
                {
                  key: '2',
                  label: (
                    <span>
                      <StyledDollarDownIcon />
                      {t('performance-dashboard.chart.expenses')}
                    </span>
                  ),
                  children: <CategoryCard data={performanceData} category_type="Expenses" />,
                },
              ]}
              style={{ width: '100%', textAlign: 'center' }}
            />
          </StyledCard>
          <StyledCard
            title={
              <>
                <CategoryTitle>{t('performance-dashboard.label.title')}</CategoryTitle>
                <DateRange>{formatDateRange(selectedFocusStartDate, selectedFocusEndDate, monthNames)}</DateRange>
              </>
            }
          >
            <BaseTabs
              defaultActiveKey="1"
              items={[
                {
                  key: '1',
                  label: (
                    <span>
                      <StyledDollarUpIcon />
                      {t('performance-dashboard.chart.income')}
                    </span>
                  ),
                  children: <LabelCard data={performanceData} category_type="Income" />,
                },
                {
                  key: '2',
                  label: (
                    <span>
                      <StyledDollarDownIcon />
                      {t('performance-dashboard.chart.expenses')}
                    </span>
                  ),
                  children: <LabelCard data={performanceData} category_type="Expenses" />,
                },
              ]}
              style={{ width: '100%', textAlign: 'center' }}
            />
          </StyledCard>
        </BaseRow>
      </S.LeftSideCol>
    </BaseRow>
  );

  const mobileAndTabletLayout = (
    <BaseRow gutter={[20, 20]}>
      <BaseCol span={24} style={{ display: 'flex', justifyContent: 'center' }}>
        <BaseRow gutter={[5, 5]}>
          <PropertiesSelect
            onPropertySelect={handlePropertySelect} // Assume PropertiesSelect now has a prop for this
            itemStyle={{ fontSize: '14px', cursor: 'pointer', padding: '0.5rem', marginRight: '2px' }}
          />
          <DatesSelect
            onDatePeriodBreakDownSelect={handleDatePeriodBreakDownSelect}
            itemStyle={{ fontSize: '14px', cursor: 'pointer', padding: '0.5rem', marginRight: '2px' }}
          />
        </BaseRow>
      </BaseCol>
      <BaseCol span={24} style={{ display: 'flex', justifyContent: 'center' }}>
        <BaseBreadcrumb style={{ padding: '0.05rem' }}>
          <BaseBreadcrumb.Item>
            <HomeOutlined style={{ fontSize: '20px' }} />
            <StyledPerformanceName>
              {performanceData?.property_name || t('performance-dashboard.selects.all-properties')}
            </StyledPerformanceName>
          </BaseBreadcrumb.Item>
        </BaseBreadcrumb>
      </BaseCol>
      <BaseCol id="performance" xs={24} md={24}>
        <PerformanceCard
          data={performanceData}
          setSelectedFocusStartDate={setSelectedFocusStartDate}
          setSelectedFocusEndDate={setSelectedFocusEndDate}
        />
      </BaseCol>

      <StyledCard
        title={
          <>
            <CategoryTitle>{t('performance-dashboard.category.title')}</CategoryTitle>
            <DateRange>{formatDateRange(selectedFocusStartDate, selectedFocusEndDate, monthNames)}</DateRange>
          </>
        }
      >
        <BaseTabs
          defaultActiveKey="1"
          items={[
            {
              key: '1',
              label: (
                <span>
                  <StyledDollarUpIcon />
                  {t('performance-dashboard.chart.income')}
                </span>
              ),
              children: <CategoryCard data={performanceData} category_type="Income" />,
            },
            {
              key: '2',
              label: (
                <span>
                  <StyledDollarDownIcon />
                  {t('performance-dashboard.chart.expenses')}
                </span>
              ),
              children: <CategoryCard data={performanceData} category_type="Expenses" />,
            },
          ]}
          style={{ width: '100%', textAlign: 'center' }}
        />
      </StyledCard>
      <StyledCard
        title={
          <>
            <CategoryTitle>{t('performance-dashboard.label.title')}</CategoryTitle>
            <DateRange>{formatDateRange(selectedFocusStartDate, selectedFocusEndDate, monthNames)}</DateRange>
          </>
        }
      >
        <BaseTabs
          defaultActiveKey="1"
          items={[
            {
              key: '1',
              label: (
                <span>
                  <StyledDollarUpIcon />
                  {t('performance-dashboard.chart.income')}
                </span>
              ),
              children: <LabelCard data={performanceData} category_type="Income" />,
            },
            {
              key: '2',
              label: (
                <span>
                  <StyledDollarDownIcon />
                  {t('performance-dashboard.chart.expenses')}
                </span>
              ),
              children: <LabelCard data={performanceData} category_type="Expenses" />,
            },
          ]}
          style={{ width: '100%', textAlign: 'center' }}
        />
      </StyledCard>
    </BaseRow>
  );

  return (
    <>
      <PageTitle>{t('common.performance-dashboard')}</PageTitle>
      {isDesktop ? desktopLayout : mobileAndTabletLayout}
      <FloatingNewTransactionButton />
    </>
  );
};

export default FinancialOverviewDashboardPage;
