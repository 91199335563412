import { NumericLiteral } from 'typescript';

export type Dimension = number | string;

export type ChartData = number[];

export interface UserData {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  birthday: string | null;
  phone: string | null;
  language: string;
  country_id: string | null;
  currency_id: string | null;
  picture_url: string | null;
  role: string;
  account_setup_completed: boolean;
}

export interface IncomeExpensesEntry {
  id: string;
  start_date: string;
  end_date: string;
  income: number;
  expenses: number;
}

export interface ProfitEntry {
  id: string;
  start_date: string;
  end_date: string;
  profit: number;
}

interface CategoryLabelDetail {
  name: string;
  sum: number;
  percentage: number;
}

interface CategoryData {
  type: 'Income' | 'Expenses';
  sum: number;
  names: CategoryLabelDetail[];
}

interface LabelData {
  type: 'Expenses' | 'Income';
  names: CategoryLabelDetail[];
}

export interface OccupancyRateData {
  id: string;
  start_date: string;
  end_date: string;
  occupancy_rate: number;
}
export interface AverageDailyRateData {
  id: string;
  start_date: string;
  end_date: string;
  average_daily_rate: number;
}
export interface AverageLengthOfStayData {
  id: string;
  start_date: string;
  end_date: string;
  average_length_of_stay: number;
}
export interface RevPARData {
  id: string;
  start_date: string;
  end_date: string;
  rev_par: number;
}

export interface TransactionDetails {
  transaction_id: string;
  transaction_date: string;
  property_name: string;
  category_type: string;
  category_name: string;
  label_names: string[];
  amount: number;
}

export interface TransactionsByDate {
  date: string;
  sum: number;
  transactions: TransactionDetails[];
}

export interface TransactionsData {
  total_amount: number;
  transactions: TransactionsByDate[];
}

export interface PerformanceData {
  data_view_type: string;
  date_period_breakdown: string;
  property_id: string;
  property_name: string;
  start_date: string | null;
  end_date: string | null;
  focus_start_date: string;
  focus_end_date: string;
  currency: Currency;
  income_expenses_data: IncomeExpensesEntry[];
  category_data: CategoryData[];
  label_data: LabelData[];
}

export interface TransactionTimelineData {
  date_period_breakdown: string;
  property_id: string;
  property_name: string;
  start_date: string | null;
  end_date: string | null;
  focus_start_date: string;
  focus_end_date: string;
  currency: Currency;
  income_expenses_data: IncomeExpensesEntry[];
  transactions_data: TransactionsData;
}

export interface PerformanceSTRMetricsData {
  date_period_breakdown: string;
  property_id: string;
  property_name: string;
  start_date: string | null;
  end_date: string | null;
  currency: Currency;
  profit_data: ProfitEntry[];
  occupancy_rate_data: OccupancyRateData[];
  avg_daily_rate_data: AverageDailyRateData[];
  avg_length_of_stay_data: AverageLengthOfStayData[];
  rev_par_data: RevPARData[];
}

interface Property {
  id: string;
  name: string;
  user_id: string;
  creation_date: string;
  last_updated_date: string;
  property_type_id: string;
  country_id: string;
  currency_id: string;
}

export interface PropertyWithStats extends Property {
  currency_symbol: string;
  revenue: number;
}

interface TransactionFullDetails {
  id: string;
  user_id: string;
  creation_date: string;
  last_updated_date: string;
  transaction_date: string;
  property_id: string;
  category_id: string;
  lessee: string;
  phone: string;
  host_payment_amount: number;
  amount: number;
  cancelled_reservation: boolean;
  checkin_date: string;
  departure_date: string;
  no_of_nights: number;
  notes: string;
  property_name: string;
  category_type: string;
  category_name: string;
  transaction_label_assignment_ids: string[];
  label_ids: string[];
  label_names: string[];
}

interface Currency {
  id: string;
  name: string;
  symbol: string;
}

export interface Category {
  id: string;
  name: string;
  type: string;
  user_id: string;
  creation_date: string;
  last_updated_date: string;
}

export interface CategoryWithStats extends Category {
  transactions_count: number;
  properties_count: number;
}
export interface CategoriesByType {
  type: string;
  categories: CategoryWithStats[];
}

export interface Label {
  id: string;
  name: string;
  user_id: string;
  creation_date: string;
  last_updated_date: string;
}

export interface Rate {
  id: string;
  created_at?: string;
  updated_at?: string;
  tax_info?: { [key: string]: string };
  rate_records?: Array<{ [key: string]: string | boolean }>;
}

export interface Rates {
  rates?: Rate;
  properties: Property[];
  currency_symbol: string;
}

export interface LabelWithStats extends Label {
  transactions_count: number;
  properties_count: number;
}

export interface TransactionWithCategoriesLabelsData {
  transaction_details: TransactionFullDetails;
  properties: Property[];
  categories: CategoriesByType[];
  labels: Label[];
}

export interface PropertyWithCurrencies {
  property: Property;
  user_currency_id: string;
  currencies: Currency[];
}

export interface ChartDataItem {
  value: number;
  name: string;
}

export interface SeriesDataItem {
  value: number;
  axisValueLabel: string;
  // Add other properties you might access
}

export type xData = number[] | string[];

export type LanguageType = 'de' | 'en';

export type ThemeType = 'light' | 'dark';

export interface ChartSeries {
  seriesName: string;
  value: number;
  data: {
    day: number;
    value: NumericLiteral;
  };
  name: string;
}

export type ChartSeriesData = ChartSeries[];

export type Severity = 'success' | 'error' | 'info' | 'warning';

export type TwoFactorAuthOption = 'email' | 'phone';

export type ActivityStatusType = 'sold' | 'booked' | 'added';

export enum CurrencyTypeEnum {
  USD = 'USD',
  ETH = 'ETH',
  BTC = 'BTC',
}

export interface PaymentCard {
  cvc: string;
  expiry: string;
  name: string;
  number: string;
  // eslint-disable-next-line
  focused: any;
  background: string;
  isEdit: boolean;
}
