import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useResponsive } from '@app/hooks/useResponsive';
import * as S from '@app/pages/DashboardPages/DashboardPage.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { PropertiesSelect } from '@app/components/common/selects/PropertiesSelect/PropertiesSelect';
import { DatesSelect } from '@app/components/common/selects/DateSelect/DateSelect';
import { PerformanceCard } from '@app/components/performance-dashboard/performanceCard/PerformanceCard';
import { TimelineCard } from '@app/components/performance-dashboard/timelineCard/TimelineCard';
import { useAuth } from '@app/contexts/AuthContext';
import { TransactionTimelineData } from '@app/interfaces/interfaces';
import { getThisMonthEndDate, getThisMonthStartDate } from '@app/utils/DateUtils';
import { dateSelects as configDateSelects } from 'constants/config/dateSelects';
import { BaseBreadcrumb } from '@app/components/common/BaseBreadcrumb/BaseBreadcrumb';
import { HomeOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { FloatingNewTransactionButton } from '@app/components/common/icons/FloatingNewTransactionButton';

const StyledPerformanceName = styled.span`
  font-size: 20px;
  padding: 8px;
`;

const TransactionsTimelinePage: React.FC = () => {
  const { isDesktop } = useResponsive();
  const { t } = useTranslation();
  const { currentUser } = useAuth();
  const [selectedProperty, setSelectedProperty] = useState<string | undefined>(undefined);
  const [selectedDatePeriodBreakDown, setSelectedDatePeriodBreakDown] = useState<string | undefined>('month');
  const [transactionTimelineData, setTransactionTimelineData] = useState<TransactionTimelineData | null>(null);
  const [selectedFocusStartDate, setSelectedFocusStartDate] = useState<string | undefined>(getThisMonthStartDate());
  const [selectedFocusEndDate, setSelectedFocusEndDate] = useState<string | undefined>(getThisMonthEndDate());

  // Function to be called when a new property is selected
  const handlePropertySelect = (value: string | undefined) => {
    setSelectedProperty(value);
  };

  // Function to be called when a new DatePeriodBreakDown is selected
  const handleDatePeriodBreakDownSelect = (value: unknown) => {
    const selectedValue = value as string;

    // Find the dateSelect object by its name (value) to access its id
    const selectedDateSelect = configDateSelects.find((dateSelect) => dateSelect.name === selectedValue);

    if (selectedDateSelect) {
      setSelectedDatePeriodBreakDown(selectedDateSelect.id);
    } else {
      console.error('Selected date period not found in config');
      // Handle the case where the selected value doesn't match any dateSelect
    }
  };

  useEffect(() => {
    if (!currentUser || (!selectedProperty && !selectedDatePeriodBreakDown)) {
      return;
    }
    if (currentUser && (selectedProperty || selectedDatePeriodBreakDown)) {
      // Initialize params as an empty object, then only add properties if they are defined
      const params: Record<string, string> = {};

      if (selectedProperty) params.property_id = selectedProperty;
      if (selectedDatePeriodBreakDown) params.date_period_breakdown = selectedDatePeriodBreakDown;

      // Assuming 'focus_start_date' and 'focus_end_date' are always defined as strings for this example
      if (selectedFocusStartDate) params.focus_start_date = selectedFocusStartDate;
      if (selectedFocusEndDate) params.focus_end_date = selectedFocusEndDate;

      // Now params is guaranteed to only contain string values
      const queryParams = new URLSearchParams(params).toString();

      // Function to fetch data from the backend
      const fetchData = async () => {
        try {
          const token = await currentUser.getIdToken(); // Wait for the token promise to resolve
          const response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/api/v1/performance/transactions?${queryParams}`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
              },
            },
          );
          const data = await response.json();
          setTransactionTimelineData(data); // Update state with fetched data
        } catch (error) {
          console.log(error);
        }
      };

      fetchData();
    }
  }, [selectedProperty, selectedDatePeriodBreakDown, currentUser, selectedFocusStartDate, selectedFocusEndDate]);

  const desktopLayout = (
    <BaseRow>
      <S.LeftSideCol xl={25} xxl={25}>
        <BaseRow gutter={[10, 10]}>
          <BaseCol span={24} style={{ display: 'flex', justifyContent: 'center' }}>
            <BaseRow gutter={[5, 5]}>
              <PropertiesSelect
                onPropertySelect={handlePropertySelect} // Assume PropertiesSelect now has a prop for this
                itemStyle={{ fontSize: '14px', cursor: 'pointer', padding: '10px' }}
              />
              <DatesSelect
                onDatePeriodBreakDownSelect={handleDatePeriodBreakDownSelect}
                itemStyle={{ fontSize: '14px', cursor: 'pointer', padding: '10px' }}
              />
            </BaseRow>
          </BaseCol>
          <BaseCol span={24} style={{ display: 'flex', justifyContent: 'center' }}>
            <BaseRow gutter={[5, 5]}>
              <BaseBreadcrumb style={{ padding: '0.1rem' }}>
                <BaseBreadcrumb.Item>
                  <HomeOutlined style={{ fontSize: '20px' }} />
                  <StyledPerformanceName>
                    {transactionTimelineData?.property_name || t('performance-dashboard.selects.all-properties')}
                  </StyledPerformanceName>
                </BaseBreadcrumb.Item>
              </BaseBreadcrumb>
            </BaseRow>
          </BaseCol>
          <BaseCol id="performance" xl={24} xxl={24}>
            <PerformanceCard
              data={transactionTimelineData}
              setSelectedFocusStartDate={setSelectedFocusStartDate}
              setSelectedFocusEndDate={setSelectedFocusEndDate}
            />
          </BaseCol>

          <BaseCol xl={24} xxl={24}>
            <TimelineCard data={transactionTimelineData} />
          </BaseCol>
        </BaseRow>
      </S.LeftSideCol>
    </BaseRow>
  );

  const mobileAndTabletLayout = (
    <BaseRow gutter={[20, 20]}>
      <BaseCol span={24} style={{ display: 'flex', justifyContent: 'center' }}>
        <BaseRow gutter={[5, 5]}>
          <PropertiesSelect
            onPropertySelect={handlePropertySelect} // Assume PropertiesSelect now has a prop for this
            itemStyle={{ fontSize: '14px', cursor: 'pointer', padding: '10px', marginRight: '2px' }}
          />
          <DatesSelect
            onDatePeriodBreakDownSelect={handleDatePeriodBreakDownSelect}
            itemStyle={{ fontSize: '14px', cursor: 'pointer', padding: '10px', marginRight: '2px' }}
          />
        </BaseRow>
      </BaseCol>
      <BaseCol span={24} style={{ display: 'flex', justifyContent: 'center' }}>
        <BaseBreadcrumb style={{ padding: '0.05rem' }}>
          <BaseBreadcrumb.Item>
            <HomeOutlined style={{ fontSize: '20px' }} />
            <StyledPerformanceName>
              {transactionTimelineData?.property_name || t('performance-dashboard.selects.all-properties')}
            </StyledPerformanceName>
          </BaseBreadcrumb.Item>
        </BaseBreadcrumb>
      </BaseCol>
      <BaseCol id="performance" xs={24} md={24}>
        <PerformanceCard
          data={transactionTimelineData}
          setSelectedFocusStartDate={setSelectedFocusStartDate}
          setSelectedFocusEndDate={setSelectedFocusEndDate}
        />
      </BaseCol>

      <BaseCol xl={24} xxl={12}>
        <TimelineCard data={transactionTimelineData} />
      </BaseCol>
    </BaseRow>
  );

  return (
    <>
      <PageTitle>{t('common.transactions-timeline')}</PageTitle>
      {isDesktop ? desktopLayout : mobileAndTabletLayout}
      <FloatingNewTransactionButton />
    </>
  );
};

export default TransactionsTimelinePage;
