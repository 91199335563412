import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import tw from 'twin.macro';
import styled from 'styled-components';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { ReactComponent as GoogleIcon } from '@app/assets/icons/google.svg';
import logo from '@app/assets/logo.svg';
import * as S from './LoginForm.styles';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { handleGoogleLogin, handleLogin } from '@app/utils/Firebase.js';

const LogoImage = tw.img`h-12 mx-auto`;
const LogoLink = tw.a``;

type MessageProps = {
  type: 'error' | 'success';
};

const Message = styled.p<MessageProps>`
  ${tw`mt-6 text-center py-3 rounded-lg`}
  ${(props) => (props.type === 'error' ? tw`bg-red-100 text-red-700` : tw`bg-green-100 text-green-700`)}
`;

interface LoginFormData {
  email: string;
  password: string;
}

export const initValues: LoginFormData = {
  email: 'hello@altence.com',
  password: 'some-test-pass',
};

export const LoginForm: React.FC = () => {
  const navigate = useNavigate();
  // const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState<string | null>(null); // Added state for message
  const [messageType, setMessageType] = useState<'error' | 'success' | ''>(''); // Added state for message type
  const validatedMessageType: 'error' | 'success' = messageType === '' ? 'success' : messageType;

  const logoLinkUrl = '/';

  return (
    <Auth.FormWrapper>
      <BaseForm
        layout="vertical"
        onFinish={(values) => handleLogin(values, navigate, setMessage, setMessageType)}
        requiredMark="optional"
      >
        <LogoLink href={logoLinkUrl}>
          <LogoImage src={logo} />
        </LogoLink>
        <Auth.FormTitle>{t('common.login')}</Auth.FormTitle>
        <S.LoginDescription>{t('login.loginInfo')}</S.LoginDescription>
        <Auth.FormItem
          name="email"
          label={t('common.email')}
          rules={[
            { required: true, message: t('common.requiredField') },
            {
              type: 'email',
              message: t('common.notValidEmail'),
            },
          ]}
        >
          <Auth.FormInput placeholder={t('common.email')} />
        </Auth.FormItem>
        <Auth.FormItem
          label={t('common.password')}
          name="password"
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInputPassword placeholder={t('common.password')} />
        </Auth.FormItem>
        <Auth.ActionsWrapper>
          <Link to="/auth/forgot-password">
            <S.ForgotPasswordText>{t('common.forgotPass')}</S.ForgotPasswordText>
          </Link>
        </Auth.ActionsWrapper>
        <BaseForm.Item noStyle>
          <Auth.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            {t('common.login')}
          </Auth.SubmitButton>
        </BaseForm.Item>
        <BaseForm.Item noStyle>
          <Auth.SocialButton
            type="default"
            htmlType="button"
            onClick={() => handleGoogleLogin(navigate, setMessage, setMessageType)}
          >
            <Auth.SocialIconWrapper>
              <GoogleIcon />
            </Auth.SocialIconWrapper>
            {t('login.googleLink')}
          </Auth.SocialButton>
        </BaseForm.Item>
        <Auth.FooterWrapper>
          <Auth.Text>
            {t('login.noAccount')}{' '}
            <Link to="/auth/sign-up">
              <Auth.LinkText>{t('common.here')}</Auth.LinkText>
            </Link>
          </Auth.Text>
        </Auth.FooterWrapper>
      </BaseForm>
      {message && <Message type={validatedMessageType}>{message}</Message>}
    </Auth.FormWrapper>
  );
};
