import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 20px;
  text-align: center;
`;

export const Header = styled.header`
  margin-bottom: 20px;
`;

export const Title = styled.h1`
  font-size: 2.5em;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 1.8em;
  }
`;

export const Categories = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
`;

export const CategoryCard = styled.div`
  width: 300px; /* Fixed width for better alignment */
  margin: 0 10px; /* Horizontal margin for spacing */
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }

  @media (max-width: 768px) {
    width: 80%;
    font-size: 0.9em;
  }
`;

export const CategoryTitle = styled.h2`
  font-size: 1.5em;
  margin: 0; /* Remove margin to better center the text */

  @media (max-width: 768px) {
    font-size: 1.2em;
  }
`;

export const SearchBarWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
`;

export const SearchInput = styled.input`
  width: 60%;
  padding: 10px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

export const FeaturedArticles = styled.div`
  margin-top: 40px;
`;

export const ArticleList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

export const ArticleItem = styled.li`
  margin-bottom: 10px;
  padding: 10px 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #f9f9f9;
  }

  a {
    text-decoration: none;
    color: #0073e6;

    &:hover {
      text-decoration: underline;
    }
  }
`;
