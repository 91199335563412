import {
  BankOutlined,
  DollarOutlined,
  ExperimentOutlined,
  GatewayOutlined,
  GroupOutlined,
  TagsOutlined,
  UserOutlined,
} from '@ant-design/icons';
import React from 'react';

interface AccountNavItem {
  id: number;
  name: string;
  icon: React.ReactNode;
  color: 'primary' | 'error' | 'warning' | 'success' | 'breadcrumb';
  href: string;
}

export const accountNavData: AccountNavItem[] = [
  {
    id: 1,
    name: 'profile.nav.personalInfo.title',
    icon: <UserOutlined />,
    color: 'primary',
    href: 'personal-info',
  },
  {
    id: 2,
    name: 'profile.nav.properties.title',
    icon: <BankOutlined />,
    color: 'breadcrumb',
    href: 'properties',
  },
  {
    id: 3,
    name: 'profile.nav.categories.title',
    icon: <GroupOutlined />,
    color: 'breadcrumb',
    href: 'categories',
  },
  {
    id: 4,
    name: 'profile.nav.labels.title',
    icon: <TagsOutlined />,
    color: 'breadcrumb',
    href: 'labels',
  },
  {
    id: 5,
    name: 'profile.nav.advancedRates.title',
    icon: <GatewayOutlined />,
    color: 'breadcrumb',
    href: 'advanced-rates',
  },
  {
    id: 6,
    name: 'profile.nav.bulkImport.title',
    icon: <ExperimentOutlined />,
    color: 'warning',
    href: 'bulk-import',
  },
  {
    id: 7,
    name: 'profile.nav.billing.title',
    icon: <DollarOutlined />,
    color: 'success',
    href: 'billing',
  },
];
