import { TabsProps } from 'antd';
import * as S from './BaseTabs.styles';

export type BaseTabsProps = TabsProps & {
  style?: React.CSSProperties; // Add style prop
};

export const BaseTabs: React.FC<BaseTabsProps> = ({ children, style, ...props }) => {
  return (
    <S.Tabs {...props} style={{ marginTop: '20px', textAlign: 'center', ...style }}>
      {children}
    </S.Tabs>
  );
};
