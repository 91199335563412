import styled from 'styled-components';
import { Image as AntImage } from 'antd';

export const Wrapper = styled.div`
  padding: 20px;
`;

export const BreadcrumbWrapper = styled.div`
  margin-bottom: 20px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const Sidebar = styled.aside`
  width: 250px;
  margin-right: 20px;
`;

export const MainContent = styled.div`
  flex: 1;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const Image = styled(AntImage)`
  max-width: 50%;
  margin-bottom: 20px;
  cursor: pointer;
`;

export const Title = styled.h1`
  font-size: 2em;
  margin-bottom: 20px;

  @media (max-width: 1020px) {
    font-size: 1.5em;
  }
`;

export const Text = styled.div`
  font-size: 1.2em;
  margin-bottom: 20px;

  ol {
    padding-left: 20px;
  }

  li {
    margin-bottom: 10px;
  }

  @media (max-width: 1020px) {
    font-size: 1em;

    ol {
      padding-left: 15px;
    }

    li {
      margin-bottom: 8px;
    }
  }
`;

export const VideoWrapper = styled.div`
  margin-bottom: 20px;
`;

export const ModalImage = styled(AntImage)`
  width: 100%;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
  color: #fff;
`;
