import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import * as S from './Help.styles';
import HelpMenuTransactions from './HelpMenuTransactions';
import Breadcrumbs from './Breadcrumbs';
import transactions_booking_howToGetThePayoutAmount_el from '@app/assets/images/helpCenter/transactions_booking_howToGetThePayoutAmount_el.png';
import transactions_booking_howToGetThePayoutAmount_en from '@app/assets/images/helpCenter/transactions_booking_howToGetThePayoutAmount_en.png';
import transactions_airbnb_howToGetThePayoutAmount_el from '@app/assets/images/helpCenter/transactions_airbnb_howToGetThePayoutAmount_el.png';
import transactions_airbnb_howToGetThePayoutAmount_en from '@app/assets/images/helpCenter/transactions_airbnb_howToGetThePayoutAmount_en.png';

const GetTotalAmountHelpPage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const breadcrumbItems = [
    { label: 'breadcrumbs.help', path: '/help' },
    { label: 'helpCenter.transactions', path: '/help/transactions' },
    { label: 'helpCenter.howToGetThePayoutAmount.breadcrumb' },
  ];

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleClose = () => {
    setIsModalVisible(false);
  };

  return (
    <S.Wrapper>
      <S.BreadcrumbWrapper>
        <Breadcrumbs items={breadcrumbItems} />
      </S.BreadcrumbWrapper>
      <S.ContentWrapper>
        <S.Sidebar>
          <HelpMenuTransactions />
        </S.Sidebar>
        <S.MainContent>
          <S.Title>{t('helpCenter.howToGetThePayoutAmount.title')}</S.Title>

          <section>
            <h2>Booking.com</h2>
            <S.Text>
              <ol>
                <li>{t('helpCenter.howToGetThePayoutAmount.booking.step1')}</li>
                <li>{t('helpCenter.howToGetThePayoutAmount.booking.step2')}</li>
              </ol>
              <S.Image
                preview={false}
                src={
                  i18n.language === 'el'
                    ? transactions_booking_howToGetThePayoutAmount_el
                    : transactions_booking_howToGetThePayoutAmount_en
                }
                onClick={showModal}
              />
            </S.Text>
          </section>

          <section>
            <h2>Airbnb</h2>
            <S.Text>
              <ol>
                <li>{t('helpCenter.howToGetThePayoutAmount.airbnb.step1')}</li>
                <li>{t('helpCenter.howToGetThePayoutAmount.airbnb.step2')}</li>
              </ol>
              <S.Image
                preview={false}
                src={
                  i18n.language === 'el'
                    ? transactions_airbnb_howToGetThePayoutAmount_el
                    : transactions_airbnb_howToGetThePayoutAmount_en
                }
                onClick={showModal}
              />
            </S.Text>
          </section>

          <Modal
            visible={isModalVisible}
            footer={null}
            onCancel={handleClose}
            width="80%"
            style={{ top: 20 }}
            bodyStyle={{ padding: 0, textAlign: 'center', background: 'rgba(0,0,0,0.8)' }}
            closeIcon={<S.CloseButton onClick={handleClose}>X</S.CloseButton>}
          >
            <S.ModalImage
              preview={false}
              src={
                i18n.language === 'el'
                  ? transactions_booking_howToGetThePayoutAmount_el
                  : transactions_booking_howToGetThePayoutAmount_en
              }
            />
          </Modal>
        </S.MainContent>
      </S.ContentWrapper>
    </S.Wrapper>
  );
};

export default GetTotalAmountHelpPage;
