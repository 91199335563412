import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import tw from 'twin.macro';
import styled from 'styled-components';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import * as S from './ForgotPasswordForm.styles';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { handleForgotPassword } from '@app/utils/Firebase';

type MessageProps = {
  type: 'error' | 'success';
};

const Message = styled.p<MessageProps>`
  ${tw`mt-6 text-center py-3 rounded-lg`}
  ${(props) => (props.type === 'error' ? tw`bg-red-100 text-red-700` : tw`bg-green-100 text-green-700`)}
`;

export const ForgotPasswordForm: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState<string | null>(null); // Added state for message
  const [messageType, setMessageType] = useState<'error' | 'success' | ''>(''); // Added state for message type
  const validatedMessageType: 'error' | 'success' = messageType === '' ? 'success' : messageType;

  return (
    <Auth.FormWrapper>
      <BaseForm
        layout="vertical"
        onFinish={(values) => handleForgotPassword(values, setMessage, setMessageType)}
        requiredMark="optional"
      >
        <Auth.BackWrapper onClick={() => navigate(-1)}>
          <Auth.BackIcon />
          {t('common.back')}
        </Auth.BackWrapper>
        <Auth.FormTitle>{t('common.resetPassword')}</Auth.FormTitle>
        <S.Description>{t('forgotPassword.description')}</S.Description>
        <Auth.FormItem
          name="email"
          label={t('common.email')}
          rules={[{ required: true, message: t('common.emailError') }]}
        >
          <Auth.FormInput placeholder={t('common.email')} />
        </Auth.FormItem>
        <BaseForm.Item noStyle>
          <S.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            {t('forgotPassword.sendInstructions')}
          </S.SubmitButton>
        </BaseForm.Item>
      </BaseForm>
      {message && <Message type={validatedMessageType}>{message}</Message>}
    </Auth.FormWrapper>
  );
};
