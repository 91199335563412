import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseCard } from '../../common/BaseCard/BaseCard';
import { PerformanceSTRMetricsData } from 'interfaces/interfaces';
import styled from 'styled-components';
import { ProfitChart } from './ProfitChart';

interface PerformanceCardProps {
  data: PerformanceSTRMetricsData | null;
  selectedDatePeriodBreakDown: string | undefined;
  selectedDateIndex: string | number | null;
  selectedChartZoomRange: {
    startValue: number | null;
    endValue: number | null;
  };
  onInteraction: (index: string | number, bar_id: string, initialBarsToShow: number, dataLength: number) => void;
}

export const ProfitCard: React.FC<PerformanceCardProps> = ({
  data,
  selectedDatePeriodBreakDown,
  selectedDateIndex,
  selectedChartZoomRange,
  onInteraction,
}) => {
  const { t } = useTranslation();

  const [dataIds, setDataIds] = useState<string[]>([]);
  const [dataProfit, setDataProfit] = useState<number[]>([]);
  const [dataProfitLastYear, setDataProfitLastYear] = useState<number[]>([]);
  const [dataCurrency, setDataCurrency] = useState<string>('');

  // When 'data' changes, update state for IDs, incomes, and expenses
  useEffect(() => {
    if (data && data.profit_data) {
      const ids: string[] = data.profit_data.map((item) => item.id);
      const profit: number[] = data.profit_data.map((item) => item.profit);
      const currency = data.currency.symbol;

      setDataIds(ids);
      setDataProfit(profit);
      setDataCurrency(currency);

      if (selectedDatePeriodBreakDown === 'month') {
        // Derive last year's data
        const lastYearRates: number[] = data.profit_data.map((item) => {
          const lastYearDate = new Date(item.start_date);
          lastYearDate.setFullYear(lastYearDate.getFullYear() - 1);

          // Preserve the original timezone offset
          const timezoneOffset = new Date(item.start_date).getTimezoneOffset();
          const lastYearDateWithTimezone = new Date(lastYearDate.getTime() - timezoneOffset * 60000);
          const lastYearId = lastYearDateWithTimezone.toISOString().slice(0, 7);

          const lastYearItem = data.profit_data.find((i) => i.id === lastYearId);
          return lastYearItem ? lastYearItem.profit : 0;
        });

        setDataProfitLastYear(lastYearRates);
      } else {
        setDataProfitLastYear([]); // Clear last year's data if not monthly breakdown
      }
    }
  }, [data]); // Dependency array ensures this runs when `data` changes

  return (
    <PrifitCardStyled id="activity" title={t('performance-dashboard.performance.title')} padding={0}>
      <ProfitChart
        dataIds={dataIds || []}
        dataProfit={dataProfit || []}
        dataProfitLastYear={dataProfitLastYear || []}
        selectedDatePeriodBreakDown={selectedDatePeriodBreakDown}
        dataCurrency={dataCurrency}
        selectedDateIndex={selectedDateIndex}
        selectedChartZoomRange={selectedChartZoomRange}
        onInteraction={onInteraction}
      />
    </PrifitCardStyled>
  );
};

const PrifitCardStyled = styled(BaseCard)`
  width: 100%;
  height: 100%;
`;
