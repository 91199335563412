import React, { useState } from 'react';
import { ConfigDateSelect, dateSelects as configDateSelects } from 'constants/config/dateSelects';
import { useTranslation } from 'react-i18next';
import { BaseButton } from '../../BaseButton/BaseButton';
import { BaseModal } from '../../BaseModal/BaseModal';

interface DateSelectProps {
  itemStyle?: React.CSSProperties;
  onDatePeriodBreakDownSelect: (propertyId: string | undefined) => void; // Callback function to pass the selected property ID to the parent
}

export const DatesSelect: React.FC<DateSelectProps> = ({ itemStyle, onDatePeriodBreakDownSelect }) => {
  const { t } = useTranslation();
  const [isDatePeriodBreakDownModalOpen, setIsDatePeriodBreakDownModalOpen] = useState<boolean>(false);
  const [selectedDatePeriodBreakDownId, setSelectedDatePeriodBreakDownId] = useState<string | undefined>();
  const [hoveredDatePeriodBreakDown, setHoveredDatePeriodBreakDown] = useState<string | undefined>();

  const handleDatePeriodBreakDownSelect = (dateSelected: ConfigDateSelect) => {
    setSelectedDatePeriodBreakDownId(dateSelected.id); // Update the selected property state
    onDatePeriodBreakDownSelect(dateSelected.name); // Call the passed callback function with the selected property ID
    setIsDatePeriodBreakDownModalOpen(false);
  };

  return (
    <>
      <BaseButton onClick={() => setIsDatePeriodBreakDownModalOpen(true)}>
        {t('performance-dashboard.selects.select-date-period-breakdown')}
      </BaseButton>
      <BaseModal
        title={t('performance-dashboard.selects.select-date-period-breakdown')}
        centered
        open={isDatePeriodBreakDownModalOpen}
        onOk={() => setIsDatePeriodBreakDownModalOpen(false)}
        onCancel={() => setIsDatePeriodBreakDownModalOpen(false)}
        size="small"
      >
        {configDateSelects.map((dateSelect) => (
          <div
            key={dateSelect.id}
            onMouseEnter={() => setHoveredDatePeriodBreakDown(dateSelect.id)}
            onMouseLeave={() => setHoveredDatePeriodBreakDown(undefined)}
            onClick={() => handleDatePeriodBreakDownSelect(dateSelect)}
            style={{
              ...itemStyle,
              backgroundColor:
                selectedDatePeriodBreakDownId === dateSelect.id
                  ? '#f0f0f0'
                  : hoveredDatePeriodBreakDown === dateSelect.id
                  ? '#e0e0e0'
                  : '',
              transition: 'background-color 0.3s ease',
            }}
          >
            {t(dateSelect.displayName)}
          </div>
        ))}
      </BaseModal>
    </>
  );
};
