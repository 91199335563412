import React, { useCallback, useEffect, useState } from 'react';
import { CustomerInfoTable } from '../CustomerInfoTable/CustomerInfoTable';
import { useTranslation } from 'react-i18next';
import * as S from './Tables.styles';
import { BaseAvatar } from '@app/components/common/BaseAvatar/BaseAvatar';
import { UserOutlined } from '@ant-design/icons';
import { Space, Typography } from 'antd';
import { CustomerDetails } from '@app/components/layouts/CustomerDetails';
import { notificationController } from '@app/controllers/notificationController';
import { useAuth } from '@app/contexts/AuthContext';
import Error404Page from '@app/pages/Error404Page';

const { Title, Text } = Typography;

interface CustomerInfoTablesProps {
  customerId: string | undefined;
}

export interface CustomerDetailsType {
  id: string;
  user_id: string;
  name: string;
  phone: string | undefined;
  email: string | undefined;
  created_at: string;
  updated_at: string | undefined;
  notes: string | undefined;
}

export const CustomerInfoTables: React.FC<CustomerInfoTablesProps> = ({ customerId }) => {
  const { t } = useTranslation();
  const { currentUser } = useAuth();
  const [customerDetails, setCustomerDetails] = useState<CustomerDetailsType | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState(false); // State to handle error

  const fetchCustomerDetails = useCallback(async () => {
    if (!currentUser || !customerId) return;

    setLoading(true);
    try {
      const token = await currentUser.getIdToken();
      const endpoint = `${process.env.REACT_APP_BACKEND_URL}/api/v1/lessee?lessee_id=${customerId}`;
      const response = await fetch(endpoint, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setCustomerDetails(data[0] || {});
      } else if (response.status === 404) {
        setHasError(true); // Set error state to true if status is 404
        return;
      } else {
        notificationController.error({ message: t('error.fetchCustomerDetails') });
      }
    } catch (error) {
      notificationController.error({ message: t('error.fetchCustomerDetails') });
    } finally {
      setLoading(false);
    }
  }, [customerId, t, currentUser]);

  useEffect(() => {
    fetchCustomerDetails();
  }, [fetchCustomerDetails]);

  if (hasError) {
    return <Error404Page />;
  }

  return (
    <S.TablesWrapper>
      <S.Card
        id="customer-table"
        title={
          <Space>
            <BaseAvatar size={40} icon={<UserOutlined />} />
            <Text style={{ fontSize: 'clamp(1rem, 2.5vw, 1.5rem)' }}>{customerDetails?.name}</Text>
          </Space>
        }
        padding="1.25rem 1.25rem 0"
      >
        <CustomerDetails customerDetails={customerDetails} loading={loading} />

        <Title level={4} style={{ marginTop: '3rem', fontSize: 'clamp(1rem, 2vw, 1.25rem)' }}>
          {t('common.Reservations')}
        </Title>
        {customerId ? <CustomerInfoTable customerId={customerDetails?.id} /> : null}
      </S.Card>
    </S.TablesWrapper>
  );
};
