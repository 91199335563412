import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { getAuth, onAuthStateChanged, User } from 'firebase/auth';
import { useNavigate, useLocation } from 'react-router-dom';
import { ACCOUNT_SETUP, BILLING } from '@app/components/router/AppRouter';

interface UserDetails {
  id: string;
  first_name: string;
  last_name: string;
  account_setup_completed: boolean;
  role: string;
  permissions: string[];
  has_setup_payment_method: boolean;
  membership_status: string;
  membership_is_paid: boolean;
  membership_in_trial: boolean;
}

interface AuthContextType {
  currentUser: User | null;
  userDetails: UserDetails | null;
  loading: boolean;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [userDetails, setUserDetails] = useState<UserDetails | null>(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setCurrentUser(user);
      if (user) {
        try {
          const fetchedUserData = await fetchUserData(user);
          setUserDetails(fetchedUserData);
        } catch (error) {
          console.error('Failed to fetch user details', error);
          // Optionally, handle the error
        }
      } else {
        setUserDetails(null); // Clear user details if not logged in
      }
      setLoading(false);
    });

    return unsubscribe; // Cleanup subscription on unmount
  }, [navigate]);

  useEffect(() => {
    if (!loading && userDetails) {
      if (!userDetails.account_setup_completed && location.pathname !== ACCOUNT_SETUP) {
        navigate(ACCOUNT_SETUP);
      } else if (userDetails.membership_status == 'cancelled') {
        navigate(BILLING);
      }
    }
  }, [userDetails, loading, navigate, location.pathname]); // Depend on pathname to recheck when it changes

  return (
    <AuthContext.Provider value={{ currentUser, userDetails, loading }}>{!loading && children}</AuthContext.Provider>
  );
};

async function fetchUserData(currentUser: User) {
  try {
    const token = await currentUser.getIdToken();
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/details`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const userData = await response.json();
    return userData;
  } catch (error) {
    console.error('Error fetching user data:', error);
    throw error; // Re-throw to handle it in calling component
  }
}
