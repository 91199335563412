import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import translation files
import translationEN from './locales/en/translation.json';
import translationEL from './locales/el/translation.json';

// Define the resources object
const resources = {
  en: {
    translation: translationEN,
  },
  el: {
    translation: translationEL,
  },
};

// Initialize i18n
i18n.use(initReactI18next).init({
  resources,
  whitelist: ['en', 'el'], // Specify the languages you support
  lng: 'en', // Default language

  interpolation: {
    escapeValue: false,
  },
});

console.log('i18n initialized'); // Debugging: Check initialization

export default i18n;
