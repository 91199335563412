import styled from 'styled-components';

export const TransactionItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; // Make sure it spans the full width of its parent
  padding: 8px; // Optional, for some internal spacing
  box-sizing: border-box; // Ensure padding doesn't affect overall width
  &:hover {
    background-color: #f5f5f5; // Highlight color on hover
  }
`;
