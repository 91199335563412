import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Card, Button, Descriptions, Space, Spin } from 'antd';
import { EditOutlined } from '@ant-design/icons';

interface CustomerDetailsProps {
  customerDetails: CustomerDetailsType | null;
  loading: boolean;
}

export interface CustomerDetailsType {
  id: string;
  user_id: string;
  name: string;
  phone: string | undefined;
  email: string | undefined;
  created_at: string;
  updated_at: string | undefined;
  notes: string | undefined;
}

export const CustomerDetails: React.FC<CustomerDetailsProps> = ({ customerDetails, loading }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  if (loading) {
    return <Spin tip={t('loading')} />;
  }

  if (!customerDetails) {
    return <Card>{t('noCustomerDetails')}</Card>;
  }

  return (
    <Card
      bordered={false}
      extra={
        <Button icon={<EditOutlined />} onClick={() => navigate(`/customer/${customerDetails.id}/edit`)}>
          {t('common.edit')}
        </Button>
      }
    >
      <Descriptions bordered column={1}>
        <Descriptions.Item
          label={t('common.phone')}
          labelStyle={{ width: '150px' }}
          contentStyle={{ marginLeft: '0px' }}
        >
          {customerDetails.phone || ''}
        </Descriptions.Item>
        <Descriptions.Item
          label={t('common.email')}
          labelStyle={{ width: '150px' }}
          contentStyle={{ marginLeft: '0px' }}
        >
          {customerDetails.email || ''}
        </Descriptions.Item>
        <Descriptions.Item
          label={t('forms.transactionFormLabels.notes')}
          labelStyle={{ width: '150px' }}
          contentStyle={{ marginLeft: '0px' }}
        >
          {customerDetails.notes || ''}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};
