import React from 'react';
import * as S from '../Header.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { ContactsOutlined, GatewayOutlined, LineChartOutlined, MenuOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { themeObject } from '@app/styles/themes/themeVariables';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { ReactComponent as MoneyStack } from '@app/assets/icons/money-stack.svg';
import { ReactComponent as ReceiptCutoff } from '@app/assets/icons/receipt-cutoff.svg';
import { useTranslation } from 'react-i18next';

interface MobileHeaderProps {
  toggleSider: () => void;
  isSiderOpened: boolean;
}

const PaddingContainer = styled.div`
  padding-left: 20px; // Adjust the padding as needed
  padding-right: 20px; // Adjust the padding as needed
`;

interface ClickableIconProps {
  isActive: boolean;
  activeColor: string;
  inactiveColor: string;
}

const ClickableIcon = styled.div<ClickableIconProps>`
  cursor: pointer;
  display: flex; // Use flexbox to arrange icon and title
  flex-direction: column; // Stack items vertically
  align-items: center; // Center align items
  font-size: 24px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: ${(props) => (props.isActive ? props.activeColor : props.inactiveColor)};
  &:hover {
    opacity: 0.7;
  }
`;

const IconTitle = styled.span`
  margin-top: 4px; // Space between icon and text
  font-size: 10px; // Adjust based on your design
`;

export const MobileHeader: React.FC<MobileHeaderProps> = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useAppSelector((state) => state.theme.theme);
  const location = useLocation();
  const currentPath = location.pathname;

  const isActive = (path: string) => currentPath === path;

  return (
    <S.MobileHeaderContainer color={themeObject[theme].primary}>
      <PaddingContainer>
        <BaseRow justify="space-between" align="middle">
          <BaseCol>
            <ClickableIcon
              onClick={() => navigate('/')}
              isActive={isActive('/')}
              activeColor={themeObject[theme].warning}
              inactiveColor={themeObject[theme].primary1}
            >
              <MoneyStack style={{ width: '30px', height: '25px', fill: 'currentColor' }} />
              {/* <IconTitle>{t('common.overview')}</IconTitle> */}
            </ClickableIcon>
          </BaseCol>

          <BaseCol>
            <BaseRow align="middle">
              <BaseCol>
                <ClickableIcon
                  onClick={() => navigate('/timeline')}
                  isActive={isActive('/timeline')}
                  activeColor={themeObject[theme].warning}
                  inactiveColor={themeObject[theme].primary1}
                >
                  <ReceiptCutoff style={{ width: '30px', height: '25px' }} />
                  {/* <IconTitle>{t('common.timeline')}</IconTitle> */}
                </ClickableIcon>
              </BaseCol>
            </BaseRow>
          </BaseCol>

          <BaseCol>
            <BaseRow align="middle">
              <BaseCol>
                <ClickableIcon
                  onClick={() => navigate('/performance')}
                  isActive={isActive('/performance')}
                  activeColor={themeObject[theme].warning}
                  inactiveColor={themeObject[theme].primary1}
                >
                  <LineChartOutlined style={{ fontSize: '25px' }} />
                  {/* <IconTitle>{t('common.performance')}</IconTitle> */}
                </ClickableIcon>
              </BaseCol>
            </BaseRow>
          </BaseCol>

          <BaseCol>
            <BaseRow align="middle">
              <BaseCol>
                <ClickableIcon
                  onClick={() => navigate('/tax-commission-rates')}
                  isActive={isActive('/tax-commission-rates')}
                  activeColor={themeObject[theme].warning}
                  inactiveColor={themeObject[theme].primary1}
                >
                  <GatewayOutlined style={{ fontSize: '25px' }} />
                  {/* <IconTitle>{t('common.rates')}</IconTitle> */}
                </ClickableIcon>
              </BaseCol>
            </BaseRow>
          </BaseCol>

          <BaseCol>
            <BaseRow align="middle">
              <BaseCol>
                <ClickableIcon
                  onClick={() => navigate('/customers')}
                  isActive={isActive('/customers')}
                  activeColor={themeObject[theme].warning}
                  inactiveColor={themeObject[theme].primary1}
                >
                  <ContactsOutlined style={{ fontSize: '25px' }} />
                  {/* <IconTitle>{t('common.customers')}</IconTitle> */}
                </ClickableIcon>
              </BaseCol>
            </BaseRow>
          </BaseCol>

          <BaseCol>
            <BaseRow align="middle">
              <BaseCol>
                <ClickableIcon
                  onClick={() => navigate('/account')}
                  isActive={isActive('/account')}
                  activeColor={themeObject[theme].warning}
                  inactiveColor={themeObject[theme].primary1}
                >
                  <MenuOutlined style={{ fontSize: '30px' }} />
                  {/* <IconTitle>{t('common.account')}</IconTitle> */}
                </ClickableIcon>
              </BaseCol>
            </BaseRow>
          </BaseCol>
        </BaseRow>
      </PaddingContainer>
    </S.MobileHeaderContainer>
  );
};
