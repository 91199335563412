import React from 'react';
import { useTranslation } from 'react-i18next';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';

interface BreadcrumbsProps {
  items: { label: string; path?: string }[];
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ items }) => {
  const { t } = useTranslation();

  return (
    <Breadcrumb>
      {items.map((item, index) => (
        <Breadcrumb.Item key={index}>
          {item.path ? <Link to={item.path}>{t(item.label)}</Link> : t(item.label)}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default Breadcrumbs;
