import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseChart, getDefaultTooltipStyles } from '@app/components/common/charts/BaseChart';
import { dashboardPaddings } from '@app/components/medical-dashboard/DashboardCard/DashboardCard';
import { useResponsive } from '@app/hooks/useResponsive';
import { IncomeExpensesEntry, SeriesDataItem } from '@app/interfaces/interfaces';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { themeObject } from '@app/styles/themes/themeVariables';
import { graphic } from 'echarts';

interface PerformanceChartProps {
  dataIds: string[];
  dataIncome: number[];
  dataExpenses: number[];
  dataIncomeExpensesAllEntries: IncomeExpensesEntry[];
  dataCurrency: string;
  setSelectedFocusStartDate: (date: string) => void;
  setSelectedFocusEndDate: (date: string) => void;
}

export const PerformanceChart: React.FC<PerformanceChartProps> = ({
  dataIds,
  dataIncome,
  dataExpenses,
  dataIncomeExpensesAllEntries,
  dataCurrency,
  setSelectedFocusStartDate,
  setSelectedFocusEndDate,
}) => {
  const theme = useAppSelector((state) => state.theme.theme);
  const { t } = useTranslation();
  const { isTablet, isDesktop, isMobile } = useResponsive();
  const [selectedDateIndex, setSelectedDateIndex] = useState<number | null>(null);

  const size = isDesktop ? 'xl' : isTablet ? 'md' : isMobile ? 'xs' : 'xs';

  // Determine the initial number of bars based on the device type
  const initialBarsToShow = isMobile ? 6 : 6;
  const startValue = dataIds.length > initialBarsToShow ? dataIds.length - initialBarsToShow : 0;

  // Function to handle click event on bars
  const handleClick = (params: { dataIndex: string | number; name: string }) => {
    const index = typeof params.dataIndex === 'string' ? parseInt(params.dataIndex, 10) : params.dataIndex;
    const entry = dataIncomeExpensesAllEntries.find((entry) => entry.id === params.name);
    if (entry) {
      setSelectedFocusStartDate(entry.start_date);
      setSelectedFocusEndDate(entry.end_date);
      setSelectedDateIndex(index); // index is a number
    }
  };

  const option = {
    color: new graphic.LinearGradient(0, 0, 0, 1, [
      {
        offset: 0,
        color: 'rgba(38, 194, 129, 1)',
      },
      {
        offset: 1,
        color: 'rgba(38, 194, 129, 1)',
      },
    ]),
    grid: {
      top: dashboardPaddings[size][0],
      right: dashboardPaddings[size][1],
      bottom: dashboardPaddings[size][1],
      left: dashboardPaddings[size][0],
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      axisTick: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      data: dataIds,
      position: 'bottom',
      axisLabel: {
        color: themeObject[theme].primary,
        fontWeight: 500,
        fontSize: 12,
      },
    },
    yAxis: {
      type: 'value',
      min: 0,
      axisLabel: {
        formatter: `${dataCurrency}{value}`,
        color: themeObject[theme].textLight,
        fontWeight: 500,
        fontSize: 14,
      },
    },
    series: [
      {
        barMaxWidth: 26,
        data: dataIncome,
        type: 'bar',
        itemStyle: {
          borderRadius: 7,
          // color: 'rgba(38, 194, 129, 0.5)',
          color: (params: { dataIndex: string | number; value: number }) => {
            const index = typeof params.dataIndex === 'string' ? parseInt(params.dataIndex, 10) : params.dataIndex;
            return index === selectedDateIndex ? 'rgba(38, 194, 129, 1)' : 'rgba(38, 194, 129, 0.3)';
          },
        },
        emphasis: {
          focus: 'series',
          blur: 'series',
          itemStyle: {
            color: 'rgba(38, 194, 129, 1)',
          },
        },
      },
      {
        barMaxWidth: 26,
        data: dataExpenses,
        type: 'bar',
        itemStyle: {
          borderRadius: 7,
          // color: 'rgba(214, 69, 65, 0.5)',
          color: (params: { dataIndex: string | number; value: number }) => {
            const index = typeof params.dataIndex === 'string' ? parseInt(params.dataIndex, 10) : params.dataIndex;
            return index === selectedDateIndex ? 'rgba(214, 69, 65, 1)' : 'rgba(214, 69, 65, 0.3)';
          },
        },
        emphasis: {
          focus: 'series',
          blur: 'series',
          itemStyle: {
            color: 'rgba(214, 69, 65, 1)',
          },
        },
      },
    ],
    // onclick: handleBarClick,
    dataZoom: [
      {
        type: 'inside', // Enables dragging and pinch-zooming inside the chart
        startValue: startValue, // Dynamically set based on device type
        endValue: dataIds.length - 1, // Ensures it ends at the last bar
      },
    ],
    tooltip: {
      ...getDefaultTooltipStyles(themeObject[theme]),
      trigger: 'axis',
      formatter: (data: SeriesDataItem[]) => {
        const date = data[0] ? data[0].axisValueLabel : ''; // Fetching the date from the axis value label
        const income = data[0] ? parseFloat(data[0].value.toFixed(2)) : 0; // Adjusted to directly use data
        const expenses = data[1] ? parseFloat(data[1].value.toFixed(2)) : 0; // Adjusted to directly use data
        return `${date}<br/>${t('performance-dashboard.chart.income')}: ${dataCurrency}${income}<br/>${t(
          'performance-dashboard.chart.expenses',
        )}: ${dataCurrency}${expenses}`;
      },
    },
  };

  // Define custom event handlers for the chart
  const onEvents = {
    click: handleClick, // Call handleBarClick function when a bar is clicked
  };

  return <BaseChart option={option} height="100%" onEvents={onEvents} />;
};
