import React from 'react';
import { useTranslation } from 'react-i18next';
import { PieChartCustomLegend } from '../../common/charts/PieChartCustomLegend';
import { PerformanceData, ChartDataItem } from '@app/interfaces/interfaces';
import { LegendItem } from '@app/components/common/charts/Legend/Legend';

export const CategoryCard: React.FC<{ data: PerformanceData | null; category_type: string }> = ({
  data,
  category_type,
}) => {
  const { t } = useTranslation();

  // Initialize chartData and legendData with empty arrays
  let chartData: ChartDataItem[] = [];
  let legendData: LegendItem[] = [];
  let currency: string;

  // Filter for 'Income' type category data to use in chart and legend
  if (data && category_type && data.category_data) {
    const filteredData = data.category_data.find((category) => category.type === category_type);
    currency = data.currency.symbol;

    if (filteredData) {
      // Assuming names, sum, and percentages are directly provided as shown in the provided data structure
      chartData = filteredData.names.map((nameItem) => ({
        value: category_type === 'Income' ? Number(nameItem.sum.toFixed(2)) : -Number(nameItem.sum.toFixed(2)),
        name: t(nameItem.name),
      }));

      legendData = filteredData.names.map((nameItem) => ({
        name: t(nameItem.name),
        value: `${currency}${
          category_type === 'Income' ? Number(nameItem.sum.toFixed(2)) : -Number(nameItem.sum.toFixed(2))
        } (${nameItem.percentage.toFixed(2)}%)`, // Using provided sum and percentage
        description: `${t('Total')}: ${
          category_type === 'Income' ? Number(nameItem.sum.toFixed(2)) : -Number(nameItem.sum.toFixed(2))
        }, ${t('Percentage')}: ${nameItem.percentage.toFixed(2)}%`, // Example description
      }));
    }
  }

  return (
    <PieChartCustomLegend
      name={t('performance-dashboard.category.title')}
      chartData={chartData}
      legendData={legendData}
      height="300px"
    />
  );
};
