import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as S from './HelpCenter.styles';

const HelpCenterPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <S.Wrapper>
      <S.Header>
        <S.Title>{t('helpCenter.title')}</S.Title>
      </S.Header>
      {/* <S.SearchBarWrapper>
        <S.SearchInput placeholder={t('helpCenter.searchPlaceholder')} />
      </S.SearchBarWrapper> */}
      <S.Categories>
        <Link to="/help/transactions" style={{ textDecoration: 'none' }}>
          <S.CategoryCard>
            <S.CategoryTitle>{t('helpCenter.transactions')}</S.CategoryTitle>
          </S.CategoryCard>
        </Link>
        {/* <S.CategoryCard>
          <S.CategoryTitle>{t('helpCenter.categories.booking')}</S.CategoryTitle>
        </S.CategoryCard>
        <S.CategoryCard>
          <S.CategoryTitle>{t('helpCenter.categories.payments')}</S.CategoryTitle>
        </S.CategoryCard> */}
      </S.Categories>
      {/* <S.FeaturedArticles>
        <h2>{t('helpCenter.featuredArticles')}</h2>
        <S.ArticleList>
          <S.ArticleItem>
            <Link to="/help/article1">{t('helpCenter.articles.article1')}</Link>
          </S.ArticleItem>
          <S.ArticleItem>
            <Link to="/help/article2">{t('helpCenter.articles.article2')}</Link>
          </S.ArticleItem>
          <S.ArticleItem>
            <Link to="/help/article3">{t('helpCenter.articles.article3')}</Link>
          </S.ArticleItem>
        </S.ArticleList>
      </S.FeaturedArticles> */}
    </S.Wrapper>
  );
};

export default HelpCenterPage;
