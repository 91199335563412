import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

const FloatingNewTransactionButtonStyled = styled.button`
  position: fixed; // Keep the button fixed during scrolling
  bottom: 6rem; // Distance from the bottom of the viewport
  right: 4rem; // Distance from the right of the viewport
  background-color: #4caf50; // Green background
  color: white; // White text/icon
  border: none; // No border
  cursor: pointer; // Cursor pointer on hover
  padding: 15px; // Padding inside the button
  border-radius: 50%; // Rounded corners
  font-size: 0.9rem; // Icon size
  display: flex; // Use flex to center the icon
  align-items: center; // Center align items vertically
  justify-content: center; // Center align items horizontally
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); // Slight shadow for depth
  z-index: 1000; // Ensure it's above other content

  @media (max-width: 600px) {
    bottom: 4rem; // Distance from the bottom of the viewport
    right: 1.2rem; // Distance from the right of the viewport
    font-size: 0.7rem; // Icon size
  }
`;

export const FloatingNewTransactionButton: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Tooltip title={t('forms.transactionForm.newTransaction')}>
      <FloatingNewTransactionButtonStyled aria-label="Add" onClick={() => navigate('/transaction')}>
        <PlusOutlined />
      </FloatingNewTransactionButtonStyled>
    </Tooltip>
  );
};
